'use strict';

angular.module('uasApp').component('schedulePreferenceReportPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/schedule/preference/schedule.preference.report.page.html',
  controllerAs: 'reportPageController',
  controller: function ($q, EntityService, Period, SchedulePreference, entityTranslateFilter) {
    const reportPageController = this;

    reportPageController.$onInit = function () {
      Period.query({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((periods) => {
        reportPageController.periods = _(periods).filter({ planning: true }).sortBy('start').value();
        reportPageController.period = _.head(reportPageController.periods);
      });
    };

    reportPageController.getReport = function () {
      const parameters = {
        periodId: _.get(reportPageController.period, 'id')
      };

      const entityPath = EntityService.getEntityPath(reportPageController.entity);
      parameters[entityPath] = reportPageController.entity.id;
      
      if (angular.isDefined(parameters.periodId) && _.includes(['facultyId', 'studyId'], entityPath)) {
        return SchedulePreference.persons(parameters).$promise.then((reports) => {
          return _.forEach(reports, (report) => {
            report.entity.displayName = _.defaultTo(entityTranslateFilter(report.entity), report.entity.externalId);
          });
        });
      }
      
      return $q.resolve();
    };
  }
});

'use strict';

angular.module('uasApp').component('workflowParameter', {
  bindings: {
    rootType: '@'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/workflow/workflow.parameter.html',
  controllerAs: 'workflowParameterController',
  controller: function (Workflow, i18nFilter) {
    const workflowParameterController = this;

    workflowParameterController.$onInit = function () {
      workflowParameterController.ngModelCtrl.$render = () => {
        workflowParameterController.id = `${workflowParameterController.ngModelCtrl.$modelValue || ''}`;
      };

      Workflow.query({
        rootType: workflowParameterController.rootType
      }).$promise.then((workflows) => {
        workflowParameterController.workflows = _(workflows).map((workflow) => {
          return {
            id: `${workflow.id}`,
            label: i18nFilter(workflow.titles) || workflow.name
          };
        }).sortBy('label').value();
      });
    };

    workflowParameterController.onChange = function () {
      workflowParameterController.ngModelCtrl.$setViewValue(workflowParameterController.id);
    };
  }
});

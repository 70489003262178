'use strict';

angular.module('uasApp').factory('SubjectModal', function ($uibModal, feedbackObserver, Message) {

  function edit(params) {
    $uibModal.open({
      size: 'lg',
      template: `
        <subject-edit
          subject="modalController.subject"
          root-entity="modalController.rootEntity"
          category-ids="modalController.categoryIds"
          operations="modalController.operations"
          operations-to-edit="modalController.operationsToEdit"
          on-saved="modalController.save()"
          on-cancel="modalController.close()">
        </subject-edit>
      `,
      controllerAs: 'modalController',
      controller: function ($uibModalInstance) {
        const modalController = this;

        modalController.modal = $uibModalInstance;
        modalController.subject = angular.copy(params.subject);
        modalController.rootEntity = params.rootEntity;
        modalController.categoryIds = params.categoryIds;
        modalController.operations = params.operations;
        modalController.operationsToEdit = params.operationsToEdit;

        modalController.save = function () {
          feedbackObserver.dataChanged();
          modalController.close();
          Message.addSuccessLabel('Static.Message.DataSaved');
          params.callback();
        };

        modalController.close = function () {
          modalController.modal.dismiss();
        };
      }
    });
  }

  function compare(params) {
    $uibModal.open({
      size: 'lg',
      template: `
        <subject-compare
          category-id="modalController.categoryId"
          entity="modalController.entity"
          parent="modalController.parent"
          operations="modalController.operations"
          operations-to-edit="modalController.operationsToEdit"
          on-saved="modalController.save()"
          on-cancel="modalController.close()">
        </subject-compare>
      `,
      controllerAs: 'modalController',
      controller: function ($uibModalInstance) {
        const modalController = this;

        modalController.modal = $uibModalInstance;
        modalController.categoryId = params.categoryId;
        modalController.entity = params.entity;
        modalController.parent = params.parent;
        modalController.operations = params.operations;
        modalController.operationsToEdit = params.operationsToEdit;

        modalController.save = function () {
          feedbackObserver.dataChanged();
          modalController.close();
          Message.addSuccessLabel('Static.Message.DataSaved');
          params.callback();
        };

        modalController.close = function () {
          modalController.modal.dismiss();
        };
      }
    });
  }

  return { edit, compare };

});
'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:awardQualificationsList
 * @description Shows the awardable qualifications in an entity and manages them.
 */
angular.module('uasApp').component('awardQualificationList', {
  templateUrl: 'es6/qualification/award/award.qualification.list.html',
  bindings: {
    entity: '<',
    operations: '<',
    isReadOnly: '<?',
    columns: '<?',
    workflowMode: '<?'
  },
  controllerAs: 'awardQualificationsListController',
  controller: function ($q, $uibModal, AwardQualification, Column, CustomField, EntityService, FeedbackService, Qualification, Language, Rule, activeFilter) {
    const awardQualificationsListController = this;

    awardQualificationsListController.$onInit = function () {
      awardQualificationsListController.defaultColumns = ['qualification', 'description'];
      awardQualificationsListController.entityType = EntityService.getType(awardQualificationsListController.entity);
      awardQualificationsListController.onLanguage = Language.onChange(loadData);
      awardQualificationsListController.specificationId = _.get(awardQualificationsListController.entity, 'specificationId');

      if (angular.isDefined(awardQualificationsListController.specificationId)) {
        setFields().then(loadData);
      }
    };

    awardQualificationsListController.$onDestroy = function () {
      awardQualificationsListController.onLanguage();
    };

    function setFields() {
      return CustomField.query({
        rootType: EntityService.getType(awardQualificationsListController.entity),
        entityType: 'award-qualification'
      }).$promise.then((fields) => {
        awardQualificationsListController.fields = fields;
        awardQualificationsListController.columns_ = Column.fromFields(
          fields,
          awardQualificationsListController.columns,
          undefined,
          awardQualificationsListController.defaultColumns
        );
      });
    }

    function loadData() {
      const openIds = _(awardQualificationsListController.awards)
        .filter({ open: true })
        .map('id')
        .value();

      awardQualificationsListController.loading = true;
      $q.all([
        Qualification.query({
          specificationId: awardQualificationsListController.specificationId
        }).$promise,
        AwardQualification.query({
          entityType: awardQualificationsListController.entityType,
          entityId: awardQualificationsListController.entity.id
        }).$promise
      ]).then(([qualifications, awards]) => {
        const qualificationIds = _.map(awards, 'qualification.id');
        const unused = _(qualifications).filter((qualification) => {
            return !_.includes(qualificationIds, qualification.id);
          }).map((qualification) => {
            return {
              enabled: false,
              entity: EntityService.toReference(awardQualificationsListController.entity),
              qualification
            };
          }).value();

        awardQualificationsListController.awards = _(awards)
          .map((award) => {
            return _.extend({
              enabled: activeFilter(award, true),
              open: _.includes(openIds, award.id)
            }, award);
          })
          .concat(unused)
          .orderBy(['enabled', 'sequence', 'qualification.code', 'id'], ['desc', 'asc', 'asc', 'asc'])
          .value();
      }).finally(() => {
        awardQualificationsListController.loading = false;
      });
    }

    awardQualificationsListController.onActive = function() {
      loadData();
    };

    awardQualificationsListController.onToggle = function(award) {
      if (award.enabled === true) {
        save(award);
      } else if (angular.isDefined(award.id)) {
        remove(award);
      }
    };

    function save(award) {
      const model = buildModel(award);

      return AwardQualification.store({
        comment: {
          message: ''
        },
        content: model
      }).$promise.then(() => {
        FeedbackService.onUpdate();
        loadData();
      });
    }

    function remove(entity) {
      AwardQualification.remove({
        comment: {
          message: ''
        },
        id: entity.id
      }).$promise.then(() => {
        FeedbackService.onUpdate();
        loadData();
      });
    }

    function buildModel(award) {
      const model = angular.copy(award);
      return _.extend(model, {
        qualification: _.get(award, 'qualification.id'),
        rule: _.get(award, 'rule.id')
      });
    }

    awardQualificationsListController.edit = function(award) {
      const model = buildModel(award);

      $uibModal.open({
        template: `
          <award-qualification-edit
            model="modalController.model"
            fields="modalController.fields"
            operations="modalController.operations"
            on-save="modalController.onSave(entity)"
            on-cancel="modalController.close()">
          </award-qualification-edit>
        `,
        controllerAs: 'modalController',
        controller: function($uibModalInstance) {
          const modalController = this;
          modalController.model = model;
          modalController.fields = awardQualificationsListController.fields;
          modalController.operations = awardQualificationsListController.operations;

          modalController.close = function() {
            $uibModalInstance.dismiss();
          };

          modalController.onSave = function(entity) {
            FeedbackService.onUpdate(entity);
            loadData();
            modalController.close();
          };
        }
      });
    };

    // Rule management

    awardQualificationsListController.createRule = function(award) {
      return Rule.store({
        comment: {
          message: ''
        },
        content: {
          academicYearId: sessionStorage.academicYear,
          owner: EntityService.toReference(awardQualificationsListController.entity)
        }
      }).$promise.then((result) => {
        award.rule = _(result.entities).map('entity').head();
        return save(award);
      });
    };

    awardQualificationsListController.removeRule = function(award) {
      const rule = award.rule;
      delete award.rule;
      return save(award).then(() => {
        return Rule.remove({
          comment: {
            message: ''
          },
          id: rule.id
        }).$promise;
      });
    };
  }
});

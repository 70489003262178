'use strict';

angular.module('uasApp').component('htmlText', {
  bindings: {
    inputId: '@?',
    language: '@',
    editor: '@?',
    tags: '<?',
    maxCharacters: '<?',
    isRequired: '<?',
    isDisabled: '<?',
    isReadOnly: '<?',
    editorOptions: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/html.text.html',
  controllerAs: 'htmlTextController',
  controller: function () {
    const htmlTextController = this;

    htmlTextController.$onInit = function () {
      htmlTextController.ngModelCtrl.$render = function () {
        htmlTextController.model = htmlTextController.ngModelCtrl.$modelValue;
      };
    };

    htmlTextController.onChange = function () {
      htmlTextController.ngModelCtrl.$setViewValue(htmlTextController.model);
    };
  }
});

'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:workflowValidator
 * @description
 * The workflow validator service.
 */
angular.module('uasApp').factory('WorkflowValidator', function ($q, $state, CommentModal, EntityType, UnsavedChangesModal) {

  let forms = {};
  let saveCallbacks = [];
  let commentParams;

  return { setValidity, isValid, isDirty, checkForUnsavedChanges, onSave, reset, save };

  function setValidity(form) {
    if (angular.isUndefined(form)) {
      return;
    }

    const key = getKey();
    if (!forms[key]) {
      forms[key] = [];
    }
    if (!_.find(forms[key], form)) {
      forms[key].push(form);
    }
  }

  function isValid() {
    return _.every(getForms(), (form) =>
      form === true || angular.isUndefined(form) || _.get(form, '$valid', false) === true
    );
  }

  function isDirty() {
    return _.some(getForms(), (form) =>
      _.get(form, '$dirty', false) === true
    );
  }

  function isInvalid() {
    return _.some(getForms(), (form) =>
      _.get(form, '$invalid', false)
    );
  }

  function getForms() {
    const key = getKey();

    return _.map(forms[key], (form) => {
      if (_.isFunction(form)) {
        return form();
      }
      return form;
    });
  }

  function getKey() {
    return $state.current.name;
  }

  function reset() {
    const key = getKey();
    delete forms[key];

    saveCallbacks = [];
    commentParams = undefined;
  }

  function onSave(callback, entity, commentType) {
    saveCallbacks.push(callback);

    // If there is more than one callback (rows page) then use no comment when saving
    if (saveCallbacks.length > 1) {
      commentParams = {
        saveWithComment: false
      };
    } else {
      commentParams = {
        saveWithComment: angular.isDefined(entity),
        rootType: _.get(entity, 'rootType'),
        entityType: _.get(entity, 'entityType'),
        commentType
      };
    }
  }

  function checkForUnsavedChanges(event, onDiscard) {
    if (isDirty() && !_.isEmpty(saveCallbacks)) {
      if (event) {
        event.preventDefault();
      }
      const params = _.extend({
        invalid: isInvalid(),
        onSave: saveAll,
        onDiscard
      }, commentParams);

      UnsavedChangesModal.open(params);
    } else {
      onDiscard();
    }
  }

  function saveAll(comment) {
    // Sequentially execute promises to prevent timing issues
    return _.reduce(saveCallbacks, (promise, callback) => {
      return promise.then(() => callback(comment));
    }, $q.resolve());
  }

  function save(onSuccess) {
    if (isDirty() && !_.isEmpty(saveCallbacks)) {
      const onSaveCallback = (content, comment) => saveAll(comment).then(onSuccess);

      getCommentType().then((commentType) => {
        if (commentType !== 'NEVER') {
          CommentModal.openModal(
            null,
            onSaveCallback,
            commentType
          );
        } else {
          onSaveCallback();
        }
      });
    } else {
      onSuccess();
    }
  }

  function getCommentType() {
    const commentType = _.get(commentParams, 'commentType');
    if (commentType) {
      return $q.resolve(commentType);
    }

    const entityType = _.get(commentParams, 'entityType');
    const rootType = _.get(commentParams, 'rootType', entityType);
    if (rootType || entityType) {
      return EntityType.get({
        rootType,
        entityType
      }).$promise.then((config) => {
        return _.get(config, 'commentType', 'NEVER');
      });
    }

    return $q.resolve('NEVER');
  }
});

'use strict';

angular.module('uasApp').component('scheduledActivities', {
  templateUrl: 'es6/schedule/scheduled/scheduled.activities.html',
  bindings: {
    facultyId: '<?',
    studyId: '<?',
    periodId: '<?',
    typeId: '<?',
    exam: '<?'
  },
  controllerAs: 'scheduledActivitiesController',
  controller: function ($state, Faculty, MethodType, Period, Study) {
    const scheduledActivitiesController = this;

    scheduledActivitiesController.$onInit = function () {
      scheduledActivitiesController.parameters = {
        facultyId: scheduledActivitiesController.facultyId,
        studyId: scheduledActivitiesController.studyId,
        periodId: scheduledActivitiesController.periodId,
        typeId: scheduledActivitiesController.typeId,
        exam: scheduledActivitiesController.exam
      };

      scheduledActivitiesController.loading = true;
      Faculty.query({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((faculties) => {
        scheduledActivitiesController.faculties = faculties;
        loadStudies(scheduledActivitiesController.facultyId);
        loadPeriods();
        loadTypes();
      }).finally(() => {
        scheduledActivitiesController.loading = false;
      });
    };

    function loadStudies(facultyId) {
      if (angular.isDefined(facultyId)) {
        return Study.query({ facultyId }).$promise.then((studies) => {
          scheduledActivitiesController.studies = studies;
        });
      }
    }

    function loadPeriods() {
      return Period.query({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((periods) => {
        scheduledActivitiesController.periods = _.filter(periods, { selectable: true });
      });
    }

    function loadTypes() {
      return MethodType.query({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((types) => {
        scheduledActivitiesController.types = types;
      });
    }

    scheduledActivitiesController.onFaculty = function (facultyId) {
      scheduledActivitiesController.studies = [];
      delete scheduledActivitiesController.studyId;
      loadStudies(facultyId);
    };
    
    scheduledActivitiesController.onSubmit = function () {
      $state.go('scheduled-activities', scheduledActivitiesController.parameters, { reload: true });
    };
  }
});

'use strict';

angular.module('uasApp').component('parameterCheck', {
  bindings: {
    propertyKey: '@',
    expectedValue: '@'
  },
  templateUrl: 'es6/admin/parameters/parameter.check.html',
  transclude: true,
  controllerAs: 'parameterCheckController',
  controller: function(Parameter) {
    const parameterCheckController = this;

    parameterCheckController.$onInit = function() {
      setVisible();
    };

    function setVisible() {
      parameterCheckController.loading = true;
      Parameter.load().then(() => {
        const value = Parameter.getParameterValue(parameterCheckController.propertyKey);
        parameterCheckController.visible = value === parameterCheckController.expectedValue;
      }).finally(() => {
        parameterCheckController.loading = false;
      });
    }
  }
});
'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackRead
 * @description
 */
angular.module('uasApp').component('trackRead', {
  bindings: {
    module: '<',
    period: '<'
  },
  templateUrl: 'es6/schedule/track/track.read.html',
  controllerAs: 'trackReadController',
  controller: function ($q, Activity, Module, Track, Offering, Parameter) {
    const trackReadController = this;

    trackReadController.$onInit = function() {
        Module.children({
            id: trackReadController.module.id
        }).$promise.then((children) => {
            trackReadController.children = children;
            loadData();
        });
    };

    function loadData() {
        const childrenIds = _.map(trackReadController.children, 'id');

        trackReadController.loading = true;
        $q.all([
            Activity.query({
                entityId: _.concat(trackReadController.module.id, childrenIds),
                periodId: trackReadController.period.id
            }).$promise,
            Track.query({
                entityId: _.concat(trackReadController.module.id, childrenIds),
                periodId: trackReadController.period.id
            }).$promise,
            Offering.weeks({
                entityType: 'module',
                entityId: trackReadController.module.id,
                periodId: trackReadController.period.id
            }).$promise,
            Parameter.load()
        ]).then(([activities, tracks, weeks]) => {
            const types = _(activities).map('type').uniqBy('id').sortBy('sequence').value();

            const calendarWeeks = Parameter.getParameterAsBoolean('calendar.weeks');
            _.forEach(weeks, (week) => {
                week.label = calendarWeeks ? week.yearWeek : week.week;
            });

            trackReadController.types = _.map(types, (type) => {
                type.activities = _(activities).filter((activity) => activity.type.id === type.id).sortBy('name').map((activity) => {
                    const extended = _(activity.plannings).map((planning) => {
                        const week = _.find(weeks, { week: planning.week });
                        return _(tracks).filter({ planning: planning.id }).map((track) => {
                            return _.extend(track, { week });
                        }).value();
                    }).flatten().value();

                    activity.tracks = Track.reduce(extended);
                    return activity;
                }).value();

                return type;
            });
        }).finally(() => {
            trackReadController.loading = false;
        });
    }
  }
});
'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:uasPlanner
 * @description
 * # uasPlanner
 * Planner component.
 */
angular.module('uasApp')
  .component('uasPlanner', {
    bindings: {
      entity: '<',
      operations: '<',
      showPeriod: '<?'
    },
    templateUrl: 'es6/planner/planner.html',
    controllerAs: 'plannerController',
    controller: function(CalendarPeriod, Credits, CustomField, EntityService, Link, LinkType, Message, ModuleGroup, Nodes, Qualification, Route, SecurityService, $q, $uibModal) {
        const plannerController = this;
        const LIMIT = 10;

        const manager = Nodes.manager({
            build,
            load
        });

        function build(node, parent) {
            const result = Nodes.build(node, parent);
            result.choiceRequired = result.required || isChoiceRequired(parent);

            return _.extend(result, {
                selected: node.choiceRequired || _.some(plannerController.selectedNodes, node.entity),
                open: node.choiceRequired || _.some(plannerController.openNodes, node.entity),
                limit: LIMIT
            });
        }

        function isChoiceRequired(node) {
            return _.get(node, 'choiceType.required', false);
        }

        function load(node) {
            return ModuleGroup.modules({
                id: node.id,
                active: true,
                referenceDate: CalendarPeriod.getReferenceDate(plannerController.period),
                propertyName: _.get(plannerController.fieldFilter, 'name'),
                propertyValues: _.get(plannerController.fieldFilter, 'values'),
                propertyType: _.get(plannerController.fieldFilter, 'type')
            }).$promise.then((modules) => {
                const choiceRequired = isChoiceRequired(node);
                node.modules = _.map(modules, (module) => {
                    module.moduleId = _.get(module, 'module.id');
                    module.moduleGroupId = node.id;
                    module.offeringId = _(module.offerings).map('id').head();
                    module.selected = angular.isUndefined(plannerController.routeId);
                    module.visible = true;
                    module.choiceRequired = module.required || choiceRequired;
                    return module;
                });
            });
        }

        plannerController.$onInit = function() {
            plannerController.editable = SecurityService.isAllowed('EDIT_ROUTES', plannerController.operations);
            plannerController.entityType = EntityService.getType(plannerController.entity);
            plannerController.academicYearId = sessionStorage.academicYear;
            plannerController.limit = LIMIT;
            plannerController.groups = [];

            plannerController.modes = [{
                name: 'read',
                icon: 'fa-eye',
                label: 'Static.Page.Planner.Modes.Read'
            }, {
                name: 'edit',
                icon: 'fa-pencil',
                label: 'Static.Page.Planner.Modes.Edit'
            }];

            plannerController.setMode('edit');
            loadData();
        };

        function loadData() {
            plannerController.openNodes = Nodes.getOpen(plannerController.groups);
            plannerController.selectedNodes = Nodes.getNodes(plannerController.groups, { selected: true }).map((node) => node.entity);

            plannerController.loading = true;
            $q.all([
                manager.init({
                    entity: plannerController.entity
                }),
                Credits.get({
                    entityType: plannerController.entityType,
                    entityId: plannerController.entity.id
                }).$promise,
                LinkType.query().$promise,
                getRoutes(),
                Qualification.planned({
                    entityType: plannerController.entityType,
                    entityId: plannerController.entity.id
                }).$promise
            ]).then(([groups, credits, linkTypes, routes, qualifications]) => {
                plannerController.groups = groups;
                plannerController.credits = _.get(credits, 'optimum', 0);
                plannerController.linkTypes = linkTypes;
                plannerController.routes = routes;
                plannerController.qualifications = _.orderBy(qualifications, ['sequence', 'qualification.code'], ['desc', 'asc']);

                update();
            }).finally(() => {
                plannerController.loading = false;
            });
        }

        function getRoutes() {
            return Route.query({
                entityType: plannerController.entityType,
                entityId: plannerController.entity.id
            }).$promise;
        }
        
        plannerController.setExpanded = function (expanded) {
            return manager.expand(plannerController.groups, expanded);
        };

        plannerController.setMode = function(mode) {
            plannerController.mode = mode;
        };

        plannerController.onPeriod = function () {
            loadData();
        };

        plannerController.onField = function () {
            const oldValue = _.get(plannerController.oldFieldFilter, 'values');
            const newValue = _.get(plannerController.fieldFilter, 'values');

            plannerController.oldFieldFilter = plannerController.fieldFilter;

            if (!_.isEqual(oldValue, newValue)) {
                loadData();
            }
        };

        plannerController.onSelect = function(group) {
            group.selected = group.selected !== true;
            group.open = group.selected;
            manager.prepare(group).finally(update);
        };

        plannerController.onMore = function(group) {
            group.limit += LIMIT;
        };

        plannerController.onLess = function(group) {
            group.limit -= LIMIT;
        };

        plannerController.onGroup = function(group) {
            manager.toggle(group).finally(update);
        };

        plannerController.onModule = function(module) {
            module.selected = module.selected !== true;
            update();
        };

        function update() {            
            const selected = [];
            _.forEach(plannerController.groups, (group) => updateGroup(group, selected));
            
            calculate();
        }

        function updateGroup(group, selected, parent) {
            const visible = _.get(parent, 'visible', true);
            group.visible = visible && isVisible(group, selected);

            if (group.visible === true && group.selected === true) {
                selected.push({
                    type: 'module-group', 
                    id: group.id 
                });
            }

            _.forEach(group.children, (child) => updateGroup(child, selected, group));

            updateCredits(group);
            validate(group);
        }

        function updateCredits(group) {
            const modules = getModuleCredits(group.modules);
            const children = getTotalCredits(group.children);

            if (isEmpty(group)) {
                group.total = Credits.getMinimum(group.credits);
            } else {
                group.total = modules + children;
            }
        }

        function isVisible(group, selected) {
            return _.every(group.requires, (require) => _.some(selected, require));
        }

        function calculate() {
            plannerController.selected = getTotalCredits(plannerController.groups);
            plannerController.completed = plannerController.credits > 0 && plannerController.selected >= plannerController.credits;
            
            setQualification();
        }

        function getTotalCredits(groups) {
            return _(groups).filter({ 
                selected: true,
                visible: true,
                completed: true
            }).filter((group) => {
                return group.unqualified !== true;
            }).map((group) => {
                const total = group.total || 0;
                const maximum = _.get(group, 'credits.maximum', 0);
                if (maximum) {
                    return Math.min(total, maximum);
                } else {
                    return total;
                }
            }).sum();
        }

        function getModuleCredits(modules) {
            return _(modules).filter({ 
                selected: true 
            }).map((module) => module.credits || 0).sum();
        }

        function validate(group) {
            group.choiceValid = hasValidChoice(group);
            group.creditsValid = hasValidCredits(group);
            group.requiredValid = requiredSelected(group.modules) && requiredSelected(group.children);
            group.completed = group.choiceValid && group.creditsValid && group.requiredValid;
        }

        function hasValidChoice(group) {
            const type = _.get(group, 'choiceType.code');
            if (!group.loaded || !type) {
                return true;
            }

            const value = _.get(group, 'choiceValue', 1);
            const count = getSelected(group.modules) || getSelected(group.children);
            if (type === 'EQ') {
                return count === value;
            } else if (type === 'MIN') {
                return count >= value;
            } else if (type === 'MAX') {
                return count <= value;
            } else {
                return true;
            }
        }

        function getSelected(nodes) {
            return _.filter(nodes, { selected: true }).length;
        }

        function hasValidCredits(group) {
            if (isEmpty(group)) {
                return true;
            }

            let completed = false;
            if (group.selected && group.visible) {
                const choiceRequired = isChoiceRequired(group);
                completed = Credits.isValid(group.total, group.credits, choiceRequired);
            }
            return completed;
        }

        function isEmpty(group) {
            return _.get(group, 'groupType.empty', false);
        }

        function requiredSelected(nodes) {
            return _(nodes).filter('choiceRequired').every('selected');
        }

        function setQualification() {
            const qualification = getHighestQualification();
            plannerController.qualification = qualification;

            if (angular.isDefined(qualification) && !_.isEqual(plannerController.qualification, qualification)) {
                Link.query({
                    entityType: 'qualification',
                    entityId: qualification.id
                }).$promise.then((links) => {
                    plannerController.links = _.map(links, (link) => {
                        link.type = _.find(plannerController.linkTypes, { id: link.typeId });
                        return link;
                    });
                });
            } else {
                delete plannerController.links;
            }
        }

        function getHighestQualification() {
            const nodes = Nodes.getNodes(plannerController.groups, { selected: true });
            const selected = _.map(nodes, (node) => ({ id: node.id, type: 'module-group' }));

            return _(plannerController.qualifications)
                .filter((qualification) => {
                    if (angular.isDefined(qualification.requirements)) {
                        return _.every(qualification.requirements, (requirement) => 
                            _.some(selected, requirement)
                        );
                    }
                    return true;
                })
                .map('qualification')
                .find();
        }

        plannerController.onRoute = function() {
            const route = _.find(plannerController.routes, { id: plannerController.routeId });
            if (angular.isDefined(route)) {
                plannerController.setExpanded(true).then(() => {
                    const steps = _.keyBy(route.steps, 'entity.id');
                    setSelected(plannerController.groups, steps);
                    update();
                });
            }
        };

        function setSelected(groups, steps) {
            _.forEach(groups, (group) => {
                group.selected = angular.isDefined(steps[group.id]);

                _.forEach(group.modules, (node) => {
                    const step = steps[node.moduleId];
                    node.selected = angular.isDefined(step);
                    node.offeringId = _.get(step, 'offeringId', node.offeringId);
                    node.year = _.get(step, 'year');
                });

                setSelected(group.children, steps);
            });
        }

        plannerController.reset = function() {
            delete plannerController.routeId;
            setSelected(plannerController.groups, {});
        };

        plannerController.delete = function() {
            if (angular.isDefined(plannerController.routeId)) {
                Route.remove({
                    id: plannerController.routeId
                }).$promise.then(() => {
                    plannerController.reset();
                    Message.onRemoved();
                    updateRoutes();
                });
            }
        };

        plannerController.edit = function() {
            const route = _.find(plannerController.routes, { id: plannerController.routeId });
            if (angular.isDefined(route)) {
                edit(route);
            }
        };

        plannerController.save = function() {
            const route = _.find(plannerController.routes, { id: plannerController.routeId });
            if (angular.isDefined(route)) {
                save(route);
            } else {
                const model = {
                    entity: {
                        type: plannerController.entityType,
                        id: plannerController.entity.id
                    }
                };

                edit(model);
            }
        };

        function save(route) {
            const model = angular.copy(route);
            model.steps = getSteps();

            Route.store(model).$promise.then((saved) => {
                plannerController.routeId = saved.id;
                updateRoutes();
                Message.onSaved();
            });
        }

        function updateRoutes() {
            getRoutes().then((routes) => {
                plannerController.routes = routes;
            });
        }

        function getSteps() {
            const nodes = Nodes.getNodes(plannerController.groups, { selected: true });
            const groups = _.map(nodes, (node) => {
                return { entity: node.self };
            });

            const modules = _(nodes).map('modules').flatten().filter({ selected: true }).map((node) => {
                const { moduleId, offeringId, year } = node;
                return { 
                    entity: { type: 'module', id: moduleId },
                    offeringId,
                    year
                };
            }).value();

            return _.concat(groups, modules);
        }

        function edit(route) {
            $uibModal.open({
                templateUrl: 'es6/planner/route.edit.html',
                controllerAs: 'routeController',
                controller: function($uibModalInstance) {
                    const routeController = this;

                    routeController.$onInit = function() {
                        CustomField.query({
                            rootType: plannerController.entityType,
                            entityType: 'route'
                        }).$promise.then((fields) => {
                            routeController.fields = fields;
                        });

                        routeController.route = route;
                        routeController.operations = plannerController.operations;
                    };

                    routeController.cancel = function() {
                        close();
                    };

                    routeController.submit = function() {
                        save(routeController.route);
                        close();
                    };

                    function close() {
                        $uibModalInstance.dismiss();
                    }
                }
            });
        }
    }
});

'use strict';

import { isEmpty } from 'lodash';

/**
 * @ngdoc component
 * @name uasApp.component:colorLabel
 * @description Shows a label with custom background and text color.
 */
angular.module('uasApp').component('colorLabel', {
  bindings: {
    label: '@',
    tooltipLabel: '@?',
    hideLabel: '<?',
    color: '<?',
    textColor: '<?',
    squared: '<?',
    small: '<?',
    pulsing: '<?',
    viewClasses: '@?',
    dynamicFontSize: '<?'
  },
  templateUrl: 'es6/app/color/color.label.html',
  controllerAs: 'colorLabelController',
  controller: function () {
    const colorLabelController = this;

    colorLabelController.$onChanges = function () {
      setLabel();
    };

    function setLabel() {
      colorLabelController.trimmedLabel = colorLabelController.squared ? getTrimmedLabel(colorLabelController.label) : colorLabelController.label;
      colorLabelController.tooltip = _.defaultTo(colorLabelController.tooltipLabel, colorLabelController.label);

      if (colorLabelController.dynamicFontSize) {
        colorLabelController.isSmallFont = colorLabelController.trimmedLabel.length >= 3;
      }
    }

    function getTrimmedLabel(label) {
      if (isEmpty(label)) {
        return '';
      }

      return label.trim().substring(0, 4);
    }
  }
});

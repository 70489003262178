'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:awardQualificationsListPage
 * @description Page to manage the awardable qualifications for an entity.
 */
angular.module('uasApp').component('awardQualificationListPage', {
  templateUrl: 'es6/qualification/award/award.qualification.list.page.html',
  bindings: {
    entity: '<',
    operations: '<',
    page: '<',
    workflow: '<?'
  },
  controllerAs: 'awardQualificationsListPageController',
  controller: function (Page) {
    const awardQualificationsListPageController = this;

    awardQualificationsListPageController.$onInit = function () {
      awardQualificationsListPageController.readOnly = Page.getParameterAsBoolean(awardQualificationsListPageController.page, 'readOnly', false);
      awardQualificationsListPageController.columns = Page.getParameterAsArray(awardQualificationsListPageController.page, 'columns');
      awardQualificationsListPageController.workflowMode = angular.isDefined(awardQualificationsListPageController.workflow);
    };
  }
});

'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackReportPage
 * @description
 * The trackReportPage component.
 */
angular.module('uasApp').component('trackReportPage', {
  templateUrl: 'es6/schedule/track/report/track.report.page.html',
  bindings: {
    entity: '<',
    page: '<'
  },
  controllerAs: 'trackReportPageController',
  controller: function (EntityService, Page) {
    const trackReportPageController = this;

    trackReportPageController.$onInit = function () {
      trackReportPageController.defaultTemplate = Page.getParameter(trackReportPageController.page, 'defaultTemplate');
      trackReportPageController.hideReportTemplates = Page.getParameterAsBoolean(trackReportPageController.page, 'hideReportTemplates', false);
      trackReportPageController.parameters = buildParameters();
    };

    function buildParameters() {
      const parameters = {
        exam: Page.getParameterAsBoolean(trackReportPageController.page, 'exam', false)
      };

      const entityPath = EntityService.getEntityPath(trackReportPageController.entity);
      parameters[entityPath] = trackReportPageController.entity.id;
      
      return parameters;
    }
  }
});
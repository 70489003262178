'use strict';

angular.module('uasApp').config(function($stateProvider) {

    $stateProvider
        .state('workflow-setup', {
            url: '/workflow/setup/:workflowId',
            ignoreUnsavedChangesWarning: true, // Don't show the "you have unsaved changes" warning when navigating within the wizard.
            resolve: {
                setupSteps: function() {
                    return [{
                        title: 'Static.Page.Workflow.SetupWizard.GeneralSettings',
                        route: 'workflow-setup.base-settings',
                        index: 0
                    }, {
                        title: 'Static.Page.Workflow.SetupWizard.PageSelection',
                        route: 'workflow-setup.page-selection',
                        index: 1
                    }];
                },
                workflow: function($stateParams, Workflow) {
                    if (!_.isEmpty($stateParams.workflowId)) {
                        return Workflow.get({ id: $stateParams.workflowId }).$promise;
                    }
                    return { enabled: true, pages: [], relations: [] };
                }
            },
            controllerAs: 'workflowSetupStateController',
            controller: function(setupSteps, workflow) {
                const workflowSetupStateController = this;
                workflowSetupStateController.setupSteps = setupSteps;
                workflowSetupStateController.workflow = workflow;
            },
            template: `
                <workflow-setup-wizard
                    workflow="workflowSetupStateController.workflow"
                    setup-steps="workflowSetupStateController.setupSteps">
                </workflow-setup-wizard>
            `,
            breadcrumb: {
                label: 'Static.Page.Workflow.SetupWizard.Title'
            }
        })
        .state('workflow-setup.base-settings', {
            url: '/base-settings',
            ignoreUnsavedChangesWarning: true, // Don't show the "you have unsaved changes" warning when navigating within the wizard.
            parent: 'workflow-setup',
            controllerAs: 'workflowBaseSettingsStateController',
            resolve: {
                videos: function(Video) {
                    return Video.query().$promise;
                },
                roleTypes: function(RoleType) {
                    return RoleType.query().$promise.then((roleTypes) => {
                        return _.filter(roleTypes, { ignore: false });
                    });
                }
            },
            controller: function ($scope, roleTypes, videos) {
                const workflowBaseSettingsStateController = this;
                const parentController = $scope.$parent.workflowSetupWizardController;
                workflowBaseSettingsStateController.workflow = parentController.workflow;
                workflowBaseSettingsStateController.roleTypes = roleTypes;
                workflowBaseSettingsStateController.videos = videos;
            },
            template: `
                <workflow-base-settings
                    role-types="workflowBaseSettingsStateController.roleTypes"
                    workflow="workflowBaseSettingsStateController.workflow"
                    videos="workflowBaseSettingsStateController.videos">
                </workflow-base-settings>
            `
        })
        .state('workflow-setup.page-selection', {
            url: '/page-selection',
            ignoreUnsavedChangesWarning: true, // Don't show the "you have unsaved changes" warning when navigating within the wizard.
            parent: 'workflow-setup',
            controllerAs: 'workflowPageSelectionStateController',
            resolve: {
                pages: function(Page, workflow) {
                    if (angular.isDefined(workflow.type)) {
                        return Page.query({
                            rootType: workflow.type
                        }).$promise;
                    }
                    return [];
                }
            },
            controller: function ($scope, pages) {
                const workflowPageSelectionStateController = this;
                const parentController = $scope.$parent.workflowSetupWizardController;
                workflowPageSelectionStateController.workflow = parentController.workflow;
                workflowPageSelectionStateController.pages = pages;
            },
            template: `
                <workflow-page-selection
                    pages="workflowPageSelectionStateController.pages"
                    workflow="workflowPageSelectionStateController.workflow">
                </workflow-page-selection>
            `
        })
        .state('workflow-setup.page-settings', {
            url: '/page-settings/:pageIndex',
            ignoreUnsavedChangesWarning: true, // Don't show the "you have unsaved changes" warning when navigating within the wizard.
            parent: 'workflow-setup',
            controllerAs: 'workflowPageSettingsStateController',
            resolve: {
                pageIndex: function($stateParams) {
                    if (_.isEmpty($stateParams.pageIndex)) {
                        $stateParams.pageIndex = 0;
                    }
                    return parseInt($stateParams.pageIndex);
                }
            },
            controller: function($scope, pageIndex) {
                const workflowPageSettingsStateController = this;
                const parentController = $scope.$parent.workflowSetupWizardController;

                workflowPageSettingsStateController.workflow = parentController.workflow;
                workflowPageSettingsStateController.page = _.find(parentController.workflow.pages, (page) => {
                    return page.index === pageIndex;
                });
            },
            template: `
                <workflow-page-settings
                    workflow="workflowPageSettingsStateController.workflow"
                    page="workflowPageSettingsStateController.page">
                </workflow-page-settings>
            `
        });
});

'use strict';

angular.module('uasApp').component('translateHtml', {
  bindings: {
    label: '@'
  },
  templateUrl: 'es6/i18n/translate.html.html',
  controllerAs: 'translateHtmlController',
  controller: function(Language, translateFilter) {
    const translateHtmlController = this;

    translateHtmlController.$onInit = function() {
      translateHtmlController.onLanguage = Language.onChange(() => setHtml());
      setHtml();
    };

    translateHtmlController.$onDestroy = function() {
      translateHtmlController.onLanguage();
    };

    function setHtml() {
      const html = translateFilter(translateHtmlController.label);
      translateHtmlController.html = _.unescape(html);
    }
  }
});

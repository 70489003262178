'use strict';

/**
 * Renders a single cell in the access rule overview table
 */
angular.module('uasApp').component('entityOperationsOverviewCell', {
    bindings: {
        operation: '<',
        roleType: '<',
        accessRules: '<'
    },
    template: `<i
        class="fa fa-lg"
        ng-class="entityOperationsOverviewCellCtrl.classes"
        uib-tooltip-html="entityOperationsOverviewCellCtrl.tooltip"
        tooltip-enable="entityOperationsOverviewCellCtrl.enabled"
    />`,
    controllerAs: 'entityOperationsOverviewCellCtrl',
    controller: function(translateFilter, enumFilter, i18nFilter) {
        const entityOperationsOverviewCellCtrl = this;

        entityOperationsOverviewCellCtrl.$onChanges = reload;

        function reload() {
            // Find all relation tree views for the given operation and relation type
            const matches = _.filter(entityOperationsOverviewCellCtrl.accessRules, (r) =>
                r.operation === entityOperationsOverviewCellCtrl.operation &&
                r.entity.type === entityOperationsOverviewCellCtrl.roleType.entity.type &&
                r.roleType.code === entityOperationsOverviewCellCtrl.roleType.code
            );

            const contexts = _.concat(getStates(matches), getProcessStates(matches));
            const contextsTranslated = _(contexts)
                .sortBy()
                .value()
                .join(', ');

            entityOperationsOverviewCellCtrl.enabled = contexts.length > 0;
            entityOperationsOverviewCellCtrl.tooltip = translateFilter('Static.Tab.Operations.ContextApplicable', { contexts: contextsTranslated });

            const matchesIcon = contexts.length === 0 ? 'text-success fa-check-square-o' : 'text-warning fa-check-square-o';
            entityOperationsOverviewCellCtrl.classes = matches.length === 0 ? 'fa-square-o' : matchesIcon;
        }

        function getStates(rules) {
            return _(rules)
                .map('status')
                .filter(Boolean)
                .map((status) => _.snakeCase(status).toUpperCase())
                .map((status) => enumFilter(status))
                .uniq()
                .value();

        }

        function getProcessStates(rules) {
            return _(rules)
                .map('statusType')
                .filter(Boolean)
                .map((status) => i18nFilter(status.processType.labels) + ' - ' + i18nFilter(status.labels))
                .uniq()
                .value();

        }
    }
});

'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:workloadEntityReport
 * @description
 * workloadEntityReport shows the workload on each entity divided over organisations
 */
angular.module('uasApp')
  .component('workloadEntityReport', {
    bindings: {
      entity: '<',
      operations: '<',
      process: '<?',
      rootType: '@',
      onSelect: '&'
    },
    templateUrl: 'es6/staffing/workload/workload.entity.report.html',
    controllerAs: 'workloadEntityReportController',
    controller: function ($q, entityTranslateFilter, EntityService, Language, Pageable, Period, Promises, translateFilter, Workload) {
      const workloadEntityReportController = this;
      const BATCH_SIZE = 50;

      workloadEntityReportController.$onInit = function () {
        workloadEntityReportController.academicYearId = sessionStorage.academicYear;
        workloadEntityReportController.onLanguage = Language.onChange(() => initialize(workloadEntityReportController.rows));

        loadPeriods();
        loadReport();
      };

      workloadEntityReportController.$onDestroy = function () {
        workloadEntityReportController.onLanguage();
      };

      function loadPeriods() {
        return Period.query({
          academicYearId: workloadEntityReportController.academicYearId
        }).$promise.then((periods) => {
          workloadEntityReportController.periods = _.filter(periods, { planning: true });
        });
      }

      function loadReport() {
        const pageable = Pageable.of({
          pageSize: BATCH_SIZE,
          order: 'id'
        });

        workloadEntityReportController.progress = Promises.pages(pageable, (args) => {
          const parameters = _.extend(args, {
            periodId: workloadEntityReportController.periodId || workloadEntityReportController.academicYearId,
            entityType: EntityService.getType(workloadEntityReportController.entity),
            entityId: workloadEntityReportController.entity.id,
            rootType: workloadEntityReportController.rootType
          });

          return Workload.entities(parameters).$promise;
        });
        
        workloadEntityReportController.progress.$promise.then((rows) => {
          initialize(rows);
          setTotals();
        });
      }

      function initialize(rows) {
        workloadEntityReportController.organisations = getOrganisations(rows);
        workloadEntityReportController.rows = getRows(rows);
        workloadEntityReportController.headers = getHeaders();
      }

      function getOrganisations(rows) {
        const organisations = _.get(rows, '[0].organisations', []);

        return _(organisations)
          .map('organisation')
          .map((organisation) => {
            organisation.displayName = `${entityTranslateFilter(organisation)} (${organisation.code})`;
            return organisation;
          })
          .sortBy('displayName')
          .value();
      }

      function getRows(rows) {
        return _(rows)
          .map((row) => {
            row.entity.displayName = `${entityTranslateFilter(row.entity)} (${row.entity.code})`;
            row.organisations = _.sortBy(row.organisations, organisationSorter);
            return row;
          })
          .sortBy('entity.displayName')
          .value();
      }

      function organisationSorter(item) {
        return _.findIndex(workloadEntityReportController.organisations, (organisation) => item.organisation.id === organisation.id);
      }

      function getHeaders() {
        return _.concat(
          { code: 'entity', name: translateFilter('Static.Page.Workload.Entity'), valueType: 'STRING' },
          { code: 'total', name: translateFilter('Static.Page.Workload.Total'), valueType: 'NUMBER' },
          getOrganisationHeaders()
        );
      }

      function getOrganisationHeaders() {
        return _.map(workloadEntityReportController.organisations, (organisation) => ({
          code: getHeaderCode(organisation),
          name: organisation.displayName,
          valueType: 'NUMBER'
        }));
      }

      function getHeaderCode(organisation) {
        return `organisation-${organisation.id}`;
      }

      function setTotals() {
        workloadEntityReportController.total = _.reduce(workloadEntityReportController.rows, (total, row) => total += row.total, 0);

        workloadEntityReportController.totals = _.transform(workloadEntityReportController.rows, (result, row) => {
          _.forEach(row.organisations, (organisation) => {
            const total = result[organisation.organisation.id] = (result[organisation.organisation.id] || {});

            total.hours = (total.hours || 0) + organisation.allocatedHours;
            total.percentage = (total.hours * 100 / workloadEntityReportController.total);
          });
        }, {});
      }

      workloadEntityReportController.onPeriod = function (period) {
        workloadEntityReportController.periodId = _.get(period, 'id');
        loadReport();
      };

      workloadEntityReportController.getRows = function () {
        const rows = _.map(workloadEntityReportController.rows, ({ entity, total, organisations }) => {
          const result = {
            entity: entity.displayName,
            total
          };

          _.forEach(organisations, (organisation) => {
            result[getHeaderCode(organisation.organisation)] = organisation.allocatedHours;
          });

          return result;
        });

        return $q.resolve(rows);
      };
    }
  });

'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:planboardAttributeLegend
 * @description Shows a legend of attributes.
 */
angular.module('uasApp')
  .component('planboardAttributeLegend', {
    bindings: {
      attributeValues: '<',
      selectedValue: '<',
      onSelect: '&'
    },
    templateUrl: 'es6/planboard/planboard.attribute.legend.html',
    controllerAs: 'planboardAttributeLegendController'
  });

'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:statusTypeLabel
 * @description Shows a status type label
 */
angular.module('uasApp').component('statusTypeLabel', {
  bindings: {
    statusType: '<'
  },
  templateUrl: 'es6/status/type/status.type.label.html',
  controllerAs: 'statusTypeLabelController'
});

'use strict';

angular.module('uasApp').factory('WorkflowEvaluator', function ($q, Expression, Page) {

  /**
   * @param {Array} pages - Array of page objects. Rows pages' form pages must be separately included.
   * @param {Object} entity - Entity with 'id' and 'type'.
   * @returns {Promise<Object>} Promise that resolves to an object with name-value pairs.
   */
  function getFormValues(pages, entity) {
    return Page.getWithChildrenFlattened(pages).then((combinedPages) => {
      const values = {};
      const copiedPages = angular.copy(combinedPages);

      const promises = _(copiedPages)
        .filter((page) => page.route === 'form')
        .map((page) =>
          Page.items({
            id: page.id,
            entityId: entity.self.id,
            entityType: entity.self.type
          }).$promise.then((items) => {
            const itemValues = _(items)
              .map((item) => ({
                name: item.field.name,
                values: getValue(item)
              })).value();
            _.extend(values, itemValues);
          })
        ).value();
      return $q.all(promises).then(() => values);
    });
  }

  function getValue(item) {
    let values = item.values;
    if (!_.isArray(values)) {
      values = [values];
    }
    values = _.filter(values, (value) => angular.isDefined(value) && value !== null && value !== '');

    if (item.field.multipleValues !== true) {
      return _.head(values);
    }

    return values;
  }

  /**
   * @param {Array} pages - List of page objects to evaluate.
   * @param {Object} evaluation - Evaluation context, e.g.:
   * ```json
   *  {
   *    academicYearId: String,
   *    entity: { type: String, id: String },
   *    expression: String,
   *    values: {
   *      0: { name: String, values: Array },
   *      ...,
   *      operations: [String]
   *    },
   *  }
   *  ```
   * @returns {Promise<Array<Object>>} Promise that resolves to a new array of page objects with updated visibility.
   */
  function evaluateAll(pages, evaluation) {
    const copiedEvaluation = angular.copy(evaluation);

    const promises = _.map(pages, (page) =>
      evaluate(page, copiedEvaluation).then((newPage) => newPage)
    );

    return $q.all(promises);
  }

  /**
   * @param {Object} page - Page object to evaluate, determining its visibility.
   * @param {Object} evaluation - Evaluation context.
   * @returns {Promise<Object>} A promise that resolves to a new page object with an updated 'visible' property.
   */
  function evaluate(page, evaluation) {
    if (page.condition && !_.isEmpty(evaluation)) {
      return Expression.evaluate({
        ...evaluation,
        expression: page.condition,
        academicYearId: sessionStorage.academicYear
      }).$promise.then((result) => {
        return { ...page, visible: result.value === true };
      });
    }

    return $q.resolve({ ...page, visible: true });
  }

  return { getFormValues, evaluateAll };

});

'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:facultyOptions
 * @description Shows a select with faculties active in a given academic year.
 */
angular.module('uasApp').component('facultyOptions', {
  bindings: {
    selectId: '<',
    prefixProperty: '@?',
    isDisabled: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/fields/faculty.options.html',
  controllerAs: 'facultyOptionsController',
  controller: function (Faculty) {
    const facultyOptionsController = this;

    facultyOptionsController.$onInit = function () {
      facultyOptionsController.ngModelCtrl.$render = function () {
        facultyOptionsController.model = facultyOptionsController.ngModelCtrl.$modelValue;
      };
      
      Faculty.query({
        academicYearId: sessionStorage.academicYear
      }).$promise.then((faculties) => {
        facultyOptionsController.faculties = faculties;
      });
    };

    facultyOptionsController.onChange = function () {
      facultyOptionsController.ngModelCtrl.$setViewValue(facultyOptionsController.model);
    };
  }
});

'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:itemList
 * @description
 * The itemList component.
 */
angular.module('uasApp').component('itemList', {
    templateUrl: 'es6/item/item.list.html',
    bindings: {
        entity: '<',
        type: '<',
        columns: '<?',
        commentType: '<?',
        operations: '<',
        workflowMode: '<',
        isReadOnly: '<?',
        showDates: '<?',
        showTotals: '<?',
        showMine: '<?',
        labelWidth: '<',
        vertical: '<?',
        inactive: '<?'
    },
    controllerAs: 'itemListController',
    controller: function($scope, enumFilter, Column, CustomField, EntityService, Item, ItemModal, Language, Pageable, WorkflowValidator, translateFilter) {
        const itemListController = this;

        itemListController.$onInit = function() {
            itemListController.CREATED_BY_FIELD = {
                id: _.uniqueId('CREATED_BY'),
                name: 'CREATED_BY',
                type: 'CREATED_BY',
                displayOnGeneral: true,
                extended: true
            };

            setLabels();

            itemListController.onLanguage = Language.onChange(setLabels);

            WorkflowValidator.setValidity(() => itemListController.itemForm);

            itemListController.active = itemListController.inactive !== true;
            itemListController.pageable = Pageable.of({
                order: 'active',
                reverse: true
            });

            itemListController.operationsToEdit = itemListController.workflowMode === true ? 'EDIT_ITEMS_WORKFLOW' : 'EDIT_ITEMS';

            $scope.$watch('itemListController.itemForm', () => validate());

            getColumns().then((columns) => {
                setColumns(columns);
                loadData();
            });
        };

        function setLabels() {
            itemListController.CREATED_BY_FIELD.labels = [{
                value: enumFilter('CREATED_BY')
            }];
        }

        function getColumns() {
            return CustomField.query({
                type: _.get(itemListController, 'type.id')
            }).$promise.then((fields) => {
                const columnFields = _.concat(fields, itemListController.CREATED_BY_FIELD);
                return Column.fromFields(columnFields, itemListController.columns);
            });
        }

        itemListController.$onDestroy = function() {
            WorkflowValidator.reset();
            itemListController.onLanguage();
        };

        function setColumns(columns) {
            itemListController.hasSections = _.some(columns, 'section');
            itemListController.sections = CustomField.getSections(columns);
            itemListController.columns_ = _(itemListController.sections).map('fields').flatten().value();
        }

        function loadData() {
            itemListController.loading = true;
            Item.describe({
                entityType: EntityService.getType(itemListController.entity),
                entityId: itemListController.entity.id,
                typeId: itemListController.type.id,
                showMine: itemListController.showMine,
                language: Language.get()
            }).$promise.then((items) => {
                itemListController.items = items;
                validate();
                itemListController.onMode();
            }).finally(() => {
                itemListController.loading = false;
            });
        }

        function validate() {
            const activeItems = _.filter(itemListController.items, 'active');
            delete itemListController.error;

            if (angular.isDefined(itemListController.type.min) && activeItems.length < itemListController.type.min) {
                itemListController.error = translateFilter('Static.Page.Items.MinimumFailed', { minimum: itemListController.type.min });
            }
            if (angular.isDefined(itemListController.type.max) && activeItems.length > itemListController.type.max) {
                itemListController.error = translateFilter('Static.Page.Items.MaximumFailed', { maximum: itemListController.type.max });
            }
            if (angular.isDefined(itemListController.itemForm)) {
                itemListController.itemForm.$setValidity('items', !itemListController.error);
            }
        }

        itemListController.onMode = function () {
            itemListController.filtered = _.filter(itemListController.items, (item) => !itemListController.active || item.changeType !== 'REMOVE');
        };

        itemListController.getOrder = function(field) {
            return `values.${field.name}`;
        };

        itemListController.sum = function(name) {
            return _(itemListController.filtered)
                .filter((item) => item.changeType !== 'REMOVE')
                .sumBy((item) => _.parseInt(_.get(item.values, name, 0)));
        };

        itemListController.onAction = function () {
            loadData();
        };

        itemListController.create = function () {
            const item = {
                entity: EntityService.toReference(itemListController.entity),
                type: itemListController.type.id,
                values: {}
            };

            ItemModal.open({
                item,
                type: itemListController.type,
                entity: itemListController.entity,
                operations: itemListController.operations,
                operationsToEdit: itemListController.operationsToEdit,
                commentType: itemListController.commentType,
                labelWidth: itemListController.labelWidth,
                showDates: itemListController.showDates,
                onEdit: loadData
            });
        };
    }
});

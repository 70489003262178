'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:workloadStudyReport
 * @description
 * workloadStudyReport shows the workload of each study divided over organisations
 */
angular.module('uasApp')
  .component('workloadStudyReport', {
    bindings: {
      entity: '<',
      operations: '<',
      process: '<?'
    },
    templateUrl: 'es6/staffing/workload/workload.study.report.html',
    controllerAs: 'workloadStudyReportController',
    controller: function() {
      const workloadStudyReportController = this;

      workloadStudyReportController.onStudy = function(entity) {
        workloadStudyReportController.study = entity;
      };
    }
  });

'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:itemPage
 * @description
 * The itemPage component.
 */
angular.module('uasApp').component('itemPage', {
    templateUrl: 'es6/item/item.page.html',
    bindings: {
        entity: '<',
        operations: '<',
        page: '<',
        workflow: '<?',
        isReadOnly: '<?'
    },
    controllerAs: 'itemPageController',
    controller: function(ItemType, Page) {
        const itemPageController = this;

        itemPageController.$onInit = function() {
            itemPageController.readOnly = itemPageController.isReadOnly || Page.getParameterAsBoolean(itemPageController.page, 'readOnly', false);
            itemPageController.labelWidth = Page.getParameter(itemPageController.page, 'labelWidth');
            itemPageController.vertical = Page.getParameterAsBoolean(itemPageController.page, 'vertical', false);
            itemPageController.showDates = Page.getParameterAsBoolean(itemPageController.page, 'showDates', false);
            itemPageController.showInactive = Page.getParameterAsBoolean(itemPageController.page, 'showInactive', false);
            itemPageController.showTotals = Page.getParameterAsBoolean(itemPageController.page, 'showTotals', false);
            itemPageController.showMine = Page.getParameterAsBoolean(itemPageController.page, 'showMine', false);
            itemPageController.columns = _.map(itemPageController.page.items, (item) => {
                return _.get(item, 'field.name', item.type);
            });

            loadData();
        };

        function loadData() {
            itemPageController.loading = true;
            ItemType.query({
                entityType: itemPageController.entity.self.type
            }).$promise.then((types) => {
                const code = Page.getParameter(itemPageController.page, 'code', 'code');
                itemPageController.type = _.find(types, { code: code });
            }).finally(() => {
                itemPageController.loading = false;
            });
        }
    }
});

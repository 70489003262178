'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function ($stateProvider) {

  $stateProvider.state('scheduled-activities', {
    url: '/scheduled-activities?:facultyId&:studyId&:periodId&:typeId&:exam',
    breadcrumb: {
      root: true,
      label: 'Static.Page.ScheduledActivities'
    },
    resolve: {
      facultyId: function ($stateParams) {
        return parse($stateParams, 'facultyId');
      },
      studyId: function ($stateParams) {
        return parse($stateParams, 'studyId');
      },
      periodId: function ($stateParams) {
        return parse($stateParams, 'periodId');
      },
      typeId: function ($stateParams) {
        return parse($stateParams, 'typeId');
      },
      exam: function ($stateParams) {
        return $stateParams.exam === 'true';
      }
    },
    controllerAs: 'activitiesStateController',
    controller: function ($stateParams, facultyId, studyId, periodId, typeId, exam) {
      this.facultyId = facultyId;
      this.studyId = studyId;
      this.periodId = periodId;
      this.typeId = typeId;
      this.exam = exam;
    },
    template: `
      <scheduled-activities 
        faculty-id="activitiesStateController.facultyId" 
        study-id="activitiesStateController.studyId"
        period-id="activitiesStateController.periodId"
        type-id="activitiesStateController.typeId"
        exam="activitiesStateController.exam">
      </scheduled-activities>
    `
  });

  function parse($stateParams, name) {
    const value = $stateParams[name];
    if (_.isEmpty(value)) {
      return undefined;
    }

    return parseInt(value);
  }

});

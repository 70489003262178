'use strict';
import { isEmpty } from 'lodash';

angular.module('uasApp').component('htmlTextQuill', {
  bindings: {
    inputId: '@?',
    language: '@',
    editor: '@?',
    tags: '<?',
    maxCharacters: '<?',
    isRequired: '<?',
    isDisabled: '<?',
    isReadOnly: '<?',
    editorOptions: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/app/html.text.quill.html',
  controllerAs: 'htmlTextQuillController',
  controller: function ($sce, $scope, enumFilter, Changes, Clipboard, Parameter) {
    const htmlTextQuillController = this;

    htmlTextQuillController.$onInit = function () {
      Parameter.load().then(() => loadFormats());

      htmlTextQuillController.required = htmlTextQuillController.isRequired === true;
      htmlTextQuillController.disabled = htmlTextQuillController.isDisabled === true;
      htmlTextQuillController.readOnly = htmlTextQuillController.isReadOnly === true;

      htmlTextQuillController.ngModelCtrl.$render = function () {
        htmlTextQuillController.trustedHtml = $sce.trustAsHtml(htmlTextQuillController.ngModelCtrl.$modelValue);
      };
    };

    htmlTextQuillController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'maxCharacters')) {
        if (angular.isDefined(htmlTextQuillController.maxCharacters)) {
          htmlTextQuillController.ngModelCtrl.$validators.maxCharacters = function (modelValue, viewValue) {
            if (!modelValue || !viewValue) {return true;}

            let value = modelValue || viewValue;
            if (htmlTextQuillController.countPlainText && htmlTextQuillController.plainText) {value = htmlTextQuillController.plainText;}

            return isEmpty(value) || value.length <= htmlTextQuillController.maxCharacters;
          };
        }
      }

      if (Changes.hasChanged(changes, 'tags')) {
        // get pre-translated tags, so we can pass it to the React component without issues
        // note that htmlTextQuillController.tags must be stable.
        htmlTextQuillController.translatedTags = htmlTextQuillController.tags?.map((tag) => ({
          tag,
          translation: enumFilter(tag)
        }));
      }
    };

    function loadFormats() {
      htmlTextQuillController.formats = [];
      htmlTextQuillController.options = {};

      const name = htmlTextQuillController.editor || 'html.editor';
      htmlTextQuillController.stripHtml = Parameter.getParameterAsBoolean(name + '.copypaste.clean', false);
      htmlTextQuillController.countPlainText = Parameter.getParameterAsBoolean(name + '.count.plain_text', true);

      getEditorOptions(name).forEach((format) => {
        htmlTextQuillController.options[format] = true;
        htmlTextQuillController.formats.push(format);
      });

      if (isEmpty(htmlTextQuillController.formats)) {
        htmlTextQuillController.formats = false;
      }
      if (isEmpty(htmlTextQuillController.options)) {
        htmlTextQuillController.options = false;
      }
    }

    function getEditorOptions(name) {
      if (isEmpty(htmlTextQuillController.editorOptions)) {
        return Parameter.getParameterAsList(name);
      }

      return htmlTextQuillController.editorOptions;
    }

    htmlTextQuillController.onQuillChange = ({ text, html }) => {
        htmlTextQuillController.plainText = text;
        htmlTextQuillController.ngModelCtrl.$setViewValue(html);
    };

    htmlTextQuillController.onQuillSelectionChange = (range) => {
      if (range) {return;}

      htmlTextQuillController.ngModelCtrl.$setTouched();
    };

    htmlTextQuillController.showQuillHtml = function () {
      Clipboard.copy({
        value: htmlTextQuillController.ngModelCtrl.$modelValue
      });
    };
  }
});

'use strict';

angular.module('uasApp').component('schedulePreferenceReportTable', {
  bindings: {
    periodId: '<',
    hideAmount: '<?',
    getReport: '&', // Retrieve report data. Should return a promise
    onClick: '&?'
  },
  templateUrl: 'es6/schedule/preference/schedule.preference.report.table.html',
  controllerAs: 'reportTableController',
  controller: function ($q, Changes, Element, Pageable, pagedFilter, Weekday, PREFERENCE_TYPES) {
    const reportTableController = this;

    reportTableController.$onInit = function () {
      reportTableController.pageable = Pageable.of();
    };

    reportTableController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, 'periodId')) {
        reportTableController.loading = true;

        initializeColumns().then(() =>
          loadReport()
        ).finally(() => {
          reportTableController.loading = false;
        });
      }
    };

    function initializeColumns() {
      if (reportTableController.blocks) {
        return $q.resolve();
      }

      return $q.all([
        Element.getActive('TIME_BLOCK'),
        Weekday.getVisible()
      ]).then(([blocks, weekDays]) => {
        reportTableController.days = _.map(weekDays, 'day');
        reportTableController.blocks = _.sortBy(blocks, 'sequence');

        reportTableController.columns = _(reportTableController.days).map((day) => {
          return _.map(reportTableController.blocks, (block) => {
            // Inserting space just after hyphen fixes breaking up time block on hyphen in Firefox.
            // Example timeblock: 09:00-10:45.
            block.externalId = _.replace(block.externalId, /(-|–)/, '$1 ');
            return { day, block };
          });
        }).flatten().value();
      });
    }

    function loadReport() {
      return reportTableController.getReport().then((reports) => {
        delete reportTableController.reports;

        // Reports can be undefined when api call is not performed because not all parameters are available
        // In that case we do not show "No results" info
        if (angular.isDefined(reports)) {
          reportTableController.reports = _(reports)
            .map((report) => {
              const columns = buildColumns(report);
              return { entity: report.entity, columns };
            })
            .sortBy(['entity.prefix', 'entity.displayName'])
            .value();
          reportTableController.onPage();
        }
      });
    }

    function buildColumns(report) {
      return _(reportTableController.columns).map((column) => {
        return { day: column.day, timeBlockId: column.block.id };
      }).map((column) => {
        const row = _.find(report.rows, { column });
        const summaries = _(_.result(row, 'summaries') || [])
          .map((summary) => {
            const type = _.find(PREFERENCE_TYPES, { name: summary.type });
            return _.extend(summary, { type });
          })
          .sortBy((summary) => summary.type.order)
          .value();

        return _.extend(column, { summaries });
      }).value();
    }

    reportTableController.onPage = function () {
      reportTableController.pagedReports = pagedFilter(reportTableController.reports, reportTableController.pageable);
    };
  }
});

'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflowModal
 * @description
 * uasWorkflowModal Modal with an embedded workflow
 */
angular.module('uasApp')
  .component('uasWorkflowModal', {
    bindings: {
      entity: '<',
      workflow: '<',
      operations: '<?',
      extensions: '<?',
      onSave: '&',
      onCancel: '<?', // Optional cancel button
      onDelete: '<?', // Optional delete button
      onClose: '&'
    },
    templateUrl: 'es6/workflow/workflow.modal.html',
    controllerAs: 'workflowModalController',
    controller: function (Changes, Flow, WorkflowEvaluator, WorkflowValidator) {
      const workflowModalController = this;

      workflowModalController.$onInit = function () {
        workflowModalController.evaluation = {};
        workflowModalController.flow = Flow.build();

        setConditionalSteps().then(() => {
          setVisibleTabs();
          workflowModalController.goTo(_.head(workflowModalController.tabs));
        });
      };

      workflowModalController.$onChanges = function (changes) {
        if (Changes.hasChanged(changes, 'workflow')) {
          workflowModalController.pages = _.forEach(workflowModalController.workflow.pages, (page) => page.order = page.index);
        }
      };

      function setConditionalSteps() {
        return WorkflowEvaluator.getFormValues(workflowModalController.pages, workflowModalController.entity).then((values) => {
          return workflowModalController.onEvaluate({
            entity: workflowModalController.entity.self,
            values
          });
        });
      }

      function setVisibleTabs() {
        workflowModalController.tabs = _(workflowModalController.pages)
          .filter((tab) => angular.isUndefined(tab.visible) || tab.visible === true)
          .sortBy('order')
          .forEach((tab, $index) => tab.index = $index);
      }

      workflowModalController.cancel = function (event) {
        checkForUnsavedChanges(event, () => {
          onNavigate();
          workflowModalController.onCancel();
        });
      };

      function checkForUnsavedChanges(event, callback) {
        if (!event) {
          callback();
        } else {
          WorkflowValidator.checkForUnsavedChanges(event, () => {
            callback();
          });
        }
      }

      workflowModalController.close = function (event) {
        checkForUnsavedChanges(event, () => {
          onNavigate();
          workflowModalController.onClose();
        });
      };

      workflowModalController.delete = function () {
        onNavigate();
        workflowModalController.onDelete();
      };

      function onNavigate() {
        WorkflowValidator.reset();
      }

      workflowModalController.onEvaluate = function (evaluation) {
        setEvaluation(evaluation);

        return WorkflowEvaluator.evaluateAll(workflowModalController.pages, workflowModalController.evaluation).then((updatedPages) => {
          workflowModalController.pages = updatedPages;
          setVisibleTabs();
        });
      };

      function setEvaluation(evaluation) {
        _.extend(workflowModalController.evaluation, evaluation);
        _.set(workflowModalController.evaluation, 'values.operations', workflowModalController.operations);
      }

      //
      // Wizard
      //

      workflowModalController.onTab = function (tab, event) {
        checkForUnsavedChanges(event, () => {
          workflowModalController.goTo(tab);
        });
      };

      workflowModalController.goTo = function (newTab) {
        workflowModalController.currentTab = newTab;
        onNavigate();

        _.forEach(workflowModalController.tabs, (tab, $index) => {
          tab.active = $index === workflowModalController.currentTab.index;
          tab.done = $index < workflowModalController.currentTab.index;
          tab.clickable = (!tab.active && tab.done) || workflowModalController.workflow.clickableSteps === true;
        });
      };

      workflowModalController.disablePrevious = function () {
        return !WorkflowValidator.isValid();
      };

      workflowModalController.hasPrevious = function () {
        const currentIndex = _.result(workflowModalController.currentTab, 'index', 0);
        return currentIndex > 0;
      };

      workflowModalController.hasNext = function () {
        const currentIndex = _.result(workflowModalController.currentTab, 'index', 0);
        const maxIndex = _(workflowModalController.tabs).map('index').max();
        return maxIndex > currentIndex;
      };

      workflowModalController.disableNext = function () {
        return !WorkflowValidator.isValid();
      };

      workflowModalController.next = function () {
        if (workflowModalController.flow.proceed()) {
          WorkflowValidator.save(() => {
            if (workflowModalController.currentTab.index < workflowModalController.tabs.length - 1) {
              const next = workflowModalController.tabs[workflowModalController.currentTab.index + 1];
              workflowModalController.goTo(next);
            } else {
              workflowModalController.close();
            }
          });
        }
      };

      workflowModalController.previous = function () {
        WorkflowValidator.save(() => {
          const previous = workflowModalController.tabs[workflowModalController.currentTab.index - 1];
          workflowModalController.goTo(previous);
        });
      };
    }
  });

'use strict';

angular.module('uasApp')
    .directive('showPage', function() {
        function update(element, visible) {
            element.attr('style', visible ? '' : 'display:none;');
        }

        return {
            scope: {
                showPage: '@',
                pages: '='
            },
            restrict: 'A',
            replace: false,
            link: function(scope, element) {
                const path = scope.showPage;

                perform();
                scope.$watch('pages', perform);

                function perform() {
                    const page = _.find(scope.pages, { route: path });

                    if (angular.isDefined(page)) {
                        const visible = _.get(page, 'visible') !== false;
                        update(element, visible);
                    } else {
                        const defaultVisible = element.attr('default-visible') !== 'false';
                        update(element, defaultVisible);
                    }
                }
            }
        };
    });
'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasWorkflow
 * @description
 * uasWorkflow Manages a workflow.
 */
angular.module('uasApp')
  .component('uasRevertCode', {
    bindings: {
      entity: '<',
      feedback: '<',
      uibModalInstance: '<'
    },
    templateUrl: 'es6/changes/revert.code.html',
    controllerAs: 'revertCodeController',
    controller: function (EntityService, Change, $state, Message, translateFilter) {
        const revertCodeController = this;

        revertCodeController.redirect = function() {
            EntityService.redirect(revertCodeController.entity);
        };

        revertCodeController.cancel = function() {
            revertCodeController.uibModalInstance.dismiss();
        };

        revertCodeController.revert = function() {
            Change.revert({
                entityId: revertCodeController.entity.self.id,
                entityType: revertCodeController.entity.self.type,
                academicYearId: revertCodeController.entity.academicYearId,
                comment: revertCodeController.comment
            }).$promise.then((result) => {
                revertCodeController.uibModalInstance.dismiss();
                Message.addSuccess(translateFilter('Static.Page.RevertCode.Reverted', {
                    previousCode: revertCodeController.entity.code,
                    newCode: result.code
                }));
                $state.reload();
            });
        };
    }
  });

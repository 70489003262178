'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:awardQualificationFields
 * @description
 * Fields for award qualifications
 */
angular.module('uasApp').component('awardQualificationFields', {
  bindings: {
    model: '<',
    fields: '<',
    operations: '<'
  },
  templateUrl: 'es6/qualification/award/award.qualification.fields.html',
  controllerAs: 'awardQualificationFieldsController',
  controller: function (CustomField) {
    const awardQualificationFieldsController = this;

    awardQualificationFieldsController.$onChanges = function () {
      const fields = angular.copy(awardQualificationFieldsController.fields);
      awardQualificationFieldsController.fields_ = CustomField.hide(fields, ['entity', 'qualification', 'rule']);
    };

    awardQualificationFieldsController.onChange = function() {
      setEvaluation();
    };

    function setEvaluation() {
      const entity = {
        type: 'award-qualification',
        id: _.get(awardQualificationFieldsController.model, 'id')
      };

      awardQualificationFieldsController.evaluation = CustomField.getEvaluation(entity, awardQualificationFieldsController.model, awardQualificationFieldsController.fields);
    }
  }
});

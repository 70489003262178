'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Workload
 * @description
 * The Workload service.
 */
angular.module('uasApp')
  .factory('Workload', function (UasResource) {
    return UasResource('/workload', {
      report: {
        method: 'GET',
        url: '/workload-report',
        isArray: true
      },
      entities: {
        method: 'GET',
        url: '/workload-report/entities',
        isArray: false
      }
    });
  });
'use strict';

angular.module('uasApp').filter('translateHtml', function(translateFilter) {
  return function(label) {
    if (!label) {
      return '';
    }

    const html = translateFilter(label);
    return _.unescape(html);
  };
});

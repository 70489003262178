'use strict';

angular.module('uasApp').component('fieldFilter', {
  bindings: {
    rootType: '@',
    entityType: '@',
    facultyId: '<?'
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/planner/field.filter.html',
  controllerAs: 'fieldFilterController',
  controller: function ($q, capitalizeFilter, CustomField, i18nFilter, Language) {
    const fieldFilterController = this;

    const UNSUPPORTED_TYPES = [];

    fieldFilterController.$onInit = function () {
      if (!fieldFilterController.facultyId) {
        UNSUPPORTED_TYPES.push('ORGANISATION');
      }

      loadFields();
      setRender();

      fieldFilterController.ngModelCtrl.$setDirty = _.noop;
    };

    function loadFields() {
      if (!fieldFilterController.fields) {
        return CustomField.query({
          rootType: fieldFilterController.rootType,
          entityType: fieldFilterController.entityType
        }).$promise.then((fields) => {
          fieldFilterController.fields = _(fields)
            .filter('filterField')
            .filter((field) => !_.includes(UNSUPPORTED_TYPES, field.valueType))
            .map((field) => createField(field))
            .value();

          setLabels();
          Language.onChange(setLabels);
        });
      } else {
        return $q.resolve(fieldFilterController.fields);
      }
    }

    function setRender() {
      fieldFilterController.ngModelCtrl.$render = function () {
        const model = fieldFilterController.ngModelCtrl.$modelValue;
        fieldFilterController.name = _.result(model, 'name');
        fieldFilterController.value = _.result(model, 'values');

        if (fieldFilterController.name) {
          loadFields().then(() => {
            fieldFilterController.field = _.find(fieldFilterController.fields, {
              name: fieldFilterController.name
            });
          });
        }
      };
    }

    function setLabels() {
      _.forEach(fieldFilterController.fields, (field) => {
        field.label = i18nFilter(field.labels) || capitalizeFilter(field.name);
      });
      fieldFilterController.fields = _.sortBy(fieldFilterController.fields, 'label');
    }

    function createField(field) {
      const newField = _.pick(field, 'name', 'valueType', 'subType', 'labels');
      newField.modelOptions = {};

      if (newField.valueType === 'REFERENCE' || newField.valueType === 'ELEMENT') {
        newField.valueDisplayType = 'DROPDOWN';
      }

      if (field.valueType === 'DATE') {
        newField.classes = 'date';
      }

      if (newField.valueType === 'STRING') {
        newField.modelOptions.debounce = 500;
      }

      if (field.valueDisplayType === 'REFERENCE_SELECTOR') {
        newField.valueDisplayType = field.valueDisplayType;
        newField.multipleValues = true;
        newField.classes = 'selector';
      }

      return newField;
    }

    fieldFilterController.onField = function () {
      delete fieldFilterController.value;
      fieldFilterController.field = _.find(fieldFilterController.fields, {
        name: fieldFilterController.name
      });
      fieldFilterController.onChange();
    };

    fieldFilterController.onChange = function () {
      let model = {};
      if (fieldFilterController.field) {
        model.name = fieldFilterController.field.name;
        model.type = fieldFilterController.field.valueType;
        model.values = fieldFilterController.value;
      }
      fieldFilterController.ngModelCtrl.$setViewValue(model);
    };
  }
});

'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:uasPlannerPage
 * @description
 * uasPlannerPage Shows a planner in a customized page.
 */
angular.module('uasApp').component('uasPlannerPage', {
  bindings: {
    entity: '<',
    page: '<',
    operations: '<'
  },
  templateUrl: 'es6/planner/planner.page.html',
  controllerAs: 'plannerPageController',
  controller: function(Page) {
    const plannerPageController = this;

    plannerPageController.$onInit = function() {
      plannerPageController.showPeriod = Page.getParameterAsBoolean(plannerPageController.page, 'showPeriod', false);
    };
  }
});

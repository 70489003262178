'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Route
 * @description
 * The Route service.
 */
angular.module('uasApp').factory('Route', function(UasResource) {
    return UasResource('/routes');
});

'use strict';

angular.module('uasApp').factory('FeedbackService', function ($state, feedbackObserver, EntityTextHolder, EntityService) {

    function onUpdate(entity) {
        feedbackObserver.dataChanged();

        if (_.isObject(entity)) {
            EntityTextHolder.clear(EntityService.toReference(entity));
            $state.reload();
        }
    }

    return { onUpdate };
});
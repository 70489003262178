'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:processProgressBar
 * @description Shows the workflow progress as a progress bar.
 */
angular.module('uasApp').component('processProgressBar', {
    bindings: {
        process: '<',
        entity: '<',
        statuses: '<',
        viewClasses: '<?'
    },
    templateUrl: 'es6/process/progress/process.progress.bar.html',
    controllerAs: 'progressController',
    controller: function ($uibModal, EntityService, ProcessProgress) {
        const processProgressBarController = this;

        processProgressBarController.open = function() {
            $uibModal.open({
                templateUrl: 'es6/process/progress/process.progress.bar.details.html',
                keyboard: false,
                size: 'xl',
                resolve: {
                    operations: function (AuthService, Session) {
                        if (processProgressBarController.process.type.rootType === 'PERSON') {
                            return AuthService.operations('person', Session.personId);
                        } else {
                            const entity = processProgressBarController.entity.self;
                            return AuthService.operations(entity.type, entity.id);
                        }
                    }
                },
                controllerAs: 'detailsController',
                controller: function ($uibModalInstance, operations) {
                    const detailsController = this;

                    detailsController.$onInit = function () {
                        detailsController.process = processProgressBarController.process;
                        detailsController.entity = processProgressBarController.entity;
                        detailsController.operations = operations;
                        detailsController.close = $uibModalInstance.dismiss;
                        detailsController.search();
                    };

                    detailsController.search = function () {
                        let params = {
                            process: detailsController.process.id
                        };

                        if (EntityService.hasType(detailsController.entity, 'faculty')) {
                            params = _.extend(params, {
                                facultyId: detailsController.entity.id
                            });
                        }

                        if (EntityService.hasType(detailsController.entity, 'study')) {
                            params = _.extend(params, {
                              studyId: detailsController.entity.id
                            });
                        }

                        detailsController.loading = true;
                        ProcessProgress.get(params).$promise.then((result) => {
                            detailsController.result = result;
                        }).finally(() => {
                            detailsController.loading = false;
                        });
                    };
                }
            });
        };
    }
});

'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:ModuleGroupDetailsModalCtrl
 * @description
 * # ModuleGroupDetailsModalCtrl
 * The controller for viewing module group details in planboard.
 */
angular.module('uasApp')
    .controller('ModuleGroupDetailsModalCtrl', function(
        $scope, $filter, $state, $uibModalInstance, $window,
        Message, Feedback, Language, Faculty, Study, Structures,
        board, mode, group, removeMode, onLeave) {

        $scope.mode = mode;

        if (angular.isDefined(group.moduleGroupFacultyId)) {
            $scope.moduleGroupFaculty = Faculty.get({
                id: group.moduleGroupFacultyId
            });
        }
        if (angular.isDefined(group.moduleGroupStudyId)) {
            $scope.moduleGroupStudy = Study.get({
                id: group.moduleGroupStudyId
            });
        }

        $scope.required = group.required === true;

        $scope.study = board.study;
        $scope.group = group;

        $scope.removeMode = angular.isUndefined(removeMode) ? false : removeMode;
        $scope.selection = !$scope.removeMode;
        $scope.isRemoved = group.changeType === 'REMOVE';

        if (angular.isDefined(group.id)) {
            var entityType = getEntityType();
            Feedback.get({
                entityType: entityType,
                entityId: group.id,
                language: Language.get(),
                root: false
            }).$promise.then(function(feedback) {
                $scope.feedback = feedback;
            });
        }

        function getEntityType() {
            return angular.isDefined(group.parentId) ? 'module-group-module-group' : 'study-module-group';
        }

        $scope.getModuleGroupName = function() {
            return $filter('entityTranslate')($scope.group, 'moduleGroupLocalName', 'moduleGroupEnglishName');
        };

        $scope.goToModuleGroup = function() {
            const redirectUrl = $state.href('group.general', {
                id: $scope.group.moduleGroupId
            });

            $window.open(redirectUrl, '_blank');
        };

        $scope.toggleRequired = function() {
            board.toggleRequired(group);
        };

        $scope.toggleSelection = function() {
            $scope.selection = !$scope.selection;
        };

        $scope.removeModuleGroup = function() {
            board.removeModuleGroup(group);
            $uibModalInstance.close(Message.addSuccess($filter('translate')('Static.Modal.ModuleGroup.Remove', {
                name: $scope.getModuleGroupName()
            })));
        };

        $scope.edit = function () {
            onLeave(edit, () => {
                $uibModalInstance.close();
            });
        };

        function edit() {
            Structures.editGroup({
                id: $scope.group.moduleGroupId,
                entityType: getEntityType(),
                onSuccess: () => board.refreshBoard(),
                onCancel: () => board.refreshBoard()
            });
        }

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

    });

'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:Structures
 * @description
 * Collection of modals used to add elements to a structure.
 */
angular.module('uasApp').factory('Structures', function($q, $uibModal, Type, EntityType, Study, ModuleGroup, Module, StudyModuleGroup, ModuleGroupModuleGroup, GroupType, WorkflowModal) {

    const RESOURCES = {
        'study-module-group': StudyModuleGroup,
        'module-group-module-group': ModuleGroupModuleGroup
    };

    const instance = {};

    /**
     * Opens the modal to add a new module group to the current group.
     */
    instance.addGroupToStudy = function(params) {
        $uibModal.open({
            controller: function($uibModalInstance) {
                const modalFactoryController = this;
                modalFactoryController.study = params.study;
                modalFactoryController.onAdd = params.onAdd;
                modalFactoryController.uibModalInstance = $uibModalInstance;
                modalFactoryController.operations = params.operations;
                modalFactoryController.operationsToEdit = params.operationsToEdit;
                modalFactoryController.workflowMode = params.workflowMode;
            },
            controllerAs: 'modalFactoryController',
            size: 'lg',
            template: `
                <uas-module-group-add
                    study="modalFactoryController.study"
                    on-add="modalFactoryController.onAdd(group, study, message)"
                    uib-modal-instance="modalFactoryController.uibModalInstance"
                    operations="modalFactoryController.operations"
                    operations-to-edit="modalFactoryController.operationsToEdit"
                    workflow-mode="modalFactoryController.workflowMode">
                </uas-module-group-add>
            `
        });
    };

    /**
     * Opens the modal to add a new module group to the current group.
     */
     instance.addGroupToParent = function(params) {
        $uibModal.open({
            resolve: {
                parent: function() {
                    return ModuleGroup.get({
                        id: params.parent
                    }).$promise;
                }
            },
            controllerAs: 'modalFactoryController',
            controller: function(parent, $uibModalInstance) {
                const modalFactoryController = this;
                modalFactoryController.parent = parent;
                modalFactoryController.onAdd = params.onAdd;
                modalFactoryController.studyId = params.studyId;
                modalFactoryController.uibModalInstance = $uibModalInstance;
                modalFactoryController.operations = params.operations;
                modalFactoryController.operationsToEdit = params.operationsToEdit;
                modalFactoryController.workflowMode = params.workflowMode;
            },
            size: 'lg',
            template: `
                <uas-module-sub-group-add
                    parent="modalFactoryController.parent"
                    study-id="modalFactoryController.studyId"
                    on-add="modalFactoryController.onAdd(group, parent, message)"
                    uib-modal-instance="modalFactoryController.uibModalInstance"
                    operations="modalFactoryController.operations"
                    operations-to-edit="modalFactoryController.operationsToEdit"
                    workflow-mode="modalFactoryController.workflowMode">
                </uas-module-sub-group-add>
            `
        });
    };

    /**
     * Opens the modal to add a new module group to the current module.
     */
     instance.addGroupToModule = function(params) {
        $uibModal.open({
            controllerAs: 'modalFactoryController',
            controller: function($uibModalInstance) {
                const modalFactoryController = this;
                modalFactoryController.module = params.module;
                modalFactoryController.typeId = params.typeId;

                modalFactoryController.onAdd = function(group) {
                    modalFactoryController.close();
                    return params.onAdd(group);
                };

                modalFactoryController.close = function() {
                    $uibModalInstance.close();
                };
            },
            size: 'lg',
            template: `
                <uas-module-add-group
                    module="modalFactoryController.module"
                    type-id="modalFactoryController.typeId"
                    on-add="modalFactoryController.onAdd(group)"
                    on-cancel="modalFactoryController.close()">
                </uas-module-add-group>
            `
        });
    };

    /**
     * Opens the modal to add a new or existing module to the current group.
     */
    instance.addModuleToGroup = function(args) {
        $uibModal.open({
            controller: function($uibModalInstance) {
                const modalFactoryController = this;
                modalFactoryController.studyId = args.studyId;
                modalFactoryController.facultyId = args.facultyId;
                modalFactoryController.academicYearId = args.academicYearId;
                modalFactoryController.groupId = args.groupId;
                modalFactoryController.onAddNewModule = args.onAddNewModule;
                modalFactoryController.onAddExistingModule = args.onAddExistingModule;
                modalFactoryController.onDelete = args.onDelete;
                modalFactoryController.showGroupAlert = args.showGroupAlert;
                modalFactoryController.operations = args.operations;
                modalFactoryController.operationsToEdit = args.operationsToEdit;
                modalFactoryController.uibModalInstance = $uibModalInstance;
            },
            controllerAs: 'modalFactoryController',
            size: 'lg',
            template: `
                <uas-module-add
                    study-id="modalFactoryController.studyId"
                    faculty-id="modalFactoryController.facultyId"
                    academic-year-id="modalFactoryController.academicYearId"
                    group-id="modalFactoryController.groupId"
                    show-group-alert="modalFactoryController.showGroupAlert"
                    on-add-new-module="modalFactoryController.onAddNewModule(module, message)"
                    on-add-existing-module="modalFactoryController.onAddExistingModule(module, message)"
                    on-delete="modalFactoryController.onDelete()"
                    operations="modalFactoryController.operations"
                    operations-to-edit="modalFactoryController.operationsToEdit"
                    uib-modal-instance="modalFactoryController.uibModalInstance">
                </uas-module-add>
            `
        });
    };

    instance.moveModule = function(params) {
        $uibModal.open({
            resolve: {
                module: function() {
                    return Module.get({
                        id: params.moduleId
                    }).$promise;
                },
                group: function() {
                    return ModuleGroup.get({
                        id: params.groupId
                    }).$promise;
                }
            },
            controller: function (module, group, $uibModalInstance, ModuleGroupModule) {
                const moveModuleModalController = this;
                moveModuleModalController.module = module;
                moveModuleModalController.group = group;
                moveModuleModalController.entity = params.entity;
                moveModuleModalController.studyId = params.studyId;
                moveModuleModalController.open = params.open;

                moveModuleModalController.save = function(modules, targetId) {
                    const promises = _.map(modules, (mgm) => {
                        return ModuleGroupModule.move({}, {
                            id: mgm.id,
                            targetId: targetId,
                            studyId: moveModuleModalController.studyId,
                            cluster: mgm.cluster,
                            message: ''
                        }).$promise;
                    });

                    $q.all(promises).then(() => {
                        params.onSave(targetId);
                        moveModuleModalController.close();
                    });
                };

                moveModuleModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            },
            controllerAs: 'moveModuleModalController',
            size: 'lg',
            template: `
                <uas-move-module
                    entity="moveModuleModalController.entity"
                    module="moveModuleModalController.module"
                    group="moveModuleModalController.group"
                    study-id="moveModuleModalController.studyId"
                    open="moveModuleModalController.open"
                    on-move="moveModuleModalController.save(modules, targetId)"
                    on-close="moveModuleModalController.close()">
                </uas-move-module>
            `
        });
    };

    instance.editGroupInStructure = function(params) {
        const entityType = _.result(params, 'entity.type');
        const resource = RESOURCES[entityType];
        if (angular.isUndefined(resource)) {
            return;
        }

        $uibModal.open({
            resolve: {
                resolves: function(CustomField) {
                    return resource.get({
                        id: params.entity.id
                    }).$promise.then((entity) => {
                        return CustomField.query({
                            rootType: entity.root.type,
                            entityType: entity.self.type
                        }).$promise.then((fields) => [entity, fields]);
                    });
                }
            },
            controller: function (resolves, $uibModalInstance) {
                const editGroupInStructureModalController = this;
                editGroupInStructureModalController.entity = resolves[0];
                editGroupInStructureModalController.group = params.group;
                editGroupInStructureModalController.fields = resolves[1];
                editGroupInStructureModalController.resource = resource;
                editGroupInStructureModalController.operations = params.operations;
                editGroupInStructureModalController.operationsToEdit = params.operationsToEdit;
                editGroupInStructureModalController.onSave = params.onSave;

                editGroupInStructureModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            },
            controllerAs: 'editGroupInStructureModalController',
            size: 'lg',
            template: `
                <uas-edit-module-group-structure
                    entity="editGroupInStructureModalController.entity"
                    group="editGroupInStructureModalController.group"
                    fields="editGroupInStructureModalController.fields"
                    resource="editGroupInStructureModalController.resource"
                    operations="editGroupInStructureModalController.operations"
                    on-cancel="editGroupInStructureModalController.close()"
                    on-save="editGroupInStructureModalController.onSave()">
                </uas-edit-module-group-structure>
            `
      });
    };

    instance.editGroup = function(params) {
        const entityType = params.entityType;
        $q.all([
            ModuleGroup.get({
                id: params.id
            }).$promise,
            GroupType.query().$promise,
            getIncludeTypes(entityType),
            getExcludeIds(params)
        ]).then(([group, groupTypes, includeTypes, excludeTypeIds]) => {
            const extensions = {
                typeId: {
                    options: Type.getAllowed(groupTypes, includeTypes, excludeTypeIds)
                }
            };

            WorkflowModal.open({
                entity: group,
                entityType: params.entityType,
                onSave: params.onSuccess || params.onSave,
                onCancel: params.onCancel,
                extensions: extensions,
                workflowId: params.workflowId,
                workflowMode: params.workflowMode,
                template: `
                    <uas-edit-module-group
                        operations="workflowModalStateController.operations"
                        module-group="workflowModalStateController.entity"
                        entity-type="workflowModalStateController.entityType"
                        extensions="workflowModalStateController.extensions"
                        on-cancel="workflowModalStateController.onCancel()"
                        on-save="workflowModalStateController.onSave()"
                        workflow-mode="workflowModalStateController.workflowMode">
                    </uas-edit-module-group>
                `
            });
        });
    };

    function getExcludeIds(params) {
        if (_.isEmpty(params.context)) {
            return $q.resolve([]);
        }

        return GroupType.excluded(params).$promise.then((excluded) => excluded.create);
    }

    function getIncludeTypes(entityType) {
        if (!entityType) {
            return $q.resolve([]);
        }

        const rootType = entityType.replace('-module-group', '');
        return EntityType.get({
            rootType,
            entityType
        }).$promise.then((type) => {
            return EntityType.getIncludeTypes(type);
        });
    }

    instance.editModule = function(params) {
        Module.get({
          id: params.id
        }).$promise.then((module) => {
          WorkflowModal.open({
            entity: module,
            workflowId: params.workflowId,
            onSave: params.onSuccess || params.onSave,
            onCancel: params.onCancel
          });
        });
    };

    instance.editModuleInStructure = function(params) {
        $uibModal.open({
            resolve: {
                entity: function(ModuleGroupModule) {
                    return ModuleGroupModule.get({
                        id: params.entity.id
                    }).$promise;
                }
            },
            controller: function (entity, $uibModalInstance) {
                const editModuleGroupModuleModalController = this;
                editModuleGroupModuleModalController.entity = entity;
                editModuleGroupModuleModalController.module = params.module;
                editModuleGroupModuleModalController.operations = params.operations;
                editModuleGroupModuleModalController.operationsToEdit = params.operationsToEdit;
                editModuleGroupModuleModalController.onSave = params.onSave;

                editModuleGroupModuleModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            },
            controllerAs: 'editModuleGroupModuleModalController',
            size: 'lg',
            template: `
                <uas-edit-module-group-module
                    entity="editModuleGroupModuleModalController.entity"
                    module="editModuleGroupModuleModalController.module"
                    operations="editModuleGroupModuleModalController.operations"
                    operations-to-edit="editModuleGroupModuleModalController.operationsToEdit"
                    on-cancel="editModuleGroupModuleModalController.close()"
                    on-save="editModuleGroupModuleModalController.onSave()">
                </uas-edit-module-group-module>
            `
      });
    };

    instance.moveGroup = function (params) {
        $uibModal.open({
            resolve: {
                group: function() {
                    return ModuleGroup.get({
                        id: params.groupId
                    }).$promise;
                },
                parent: function() {
                    return ModuleGroup.find(params.parentId).$promise;
                },
                study: function() {
                    return Study.get({
                        id: params.studyId
                    }).$promise;
                }
            },
            controller: function ($uibModalInstance, group, parent, study) {
                const moveGroupModalController = this;
                moveGroupModalController.group = group;
                moveGroupModalController.entity = params.entity;
                moveGroupModalController.parent = parent;
                moveGroupModalController.study = study;
                moveGroupModalController.open = params.open;

                moveGroupModalController.save = function (targetId) {
                    return ModuleGroup.move({}, {
                        groupId: group.id,
                        studyId: study.id,
                        parentId: _.result(parent, 'id'),
                        targetId: targetId,
                        message: ''
                    }).$promise.then(() => {
                        params.onSave(targetId);
                        moveGroupModalController.close();
                    });
                };

                moveGroupModalController.close = function () {
                    $uibModalInstance.dismiss();
                };
            },
            controllerAs: 'moveGroupModalController',
            size: 'lg',
            template: `
                <uas-move-module-group
                    entity="moveGroupModalController.entity"
                    group="moveGroupModalController.group"
                    parent="moveGroupModalController.parent"
                    study="moveGroupModalController.study"
                    open="moveGroupModalController.open"
                    on-move="moveGroupModalController.save(targetId)"
                    on-close="moveGroupModalController.close()">
                </uas-move-module-group>
            `
        });
    };

    /**
     * Opens the modal to remove a module.
     */
     instance.removeModule = function(params) {
        $uibModal.open({
            size: 'lg',
            controllerAs: 'removeModalController',
            controller: function($uibModalInstance) {
                const removeModalController = this;
                removeModalController.module = params.module;
                removeModalController.group = params.group;
                removeModalController.entity = params.entity;
                removeModalController.studyId = params.studyId;

                removeModalController.onChange = function() {
                    removeModalController.close();
                    params.onChange();
                };

                removeModalController.close = function() {
                    $uibModalInstance.dismiss();
                };
            },
            template: `
                <uas-module-remove
                    module="removeModalController.module"
                    group="removeModalController.group"
                    entity="removeModalController.entity"
                    study-id="removeModalController.studyId"
                    on-cancel="removeModalController.close()"
                    on-change="removeModalController.onChange()">
                </uas-module-remove>
            `
        });
    };

    instance.editOffering = function (params) {
        $uibModal.open({
            controllerAs: 'editOfferingController',
            controller: function ($uibModalInstance) {
                const editOfferingController = this;

                editOfferingController.module = params.module;
                editOfferingController.operations = params.operations;
                editOfferingController.hideEdit = params.hideEdit;
                editOfferingController.workflowMode = params.workflowMode;

                editOfferingController.close = function () {
                    $uibModalInstance.dismiss();
                    params.onClose();
                };
            },
            size: 'lg',
            template: `
                <edit-module-offering
                    module="editOfferingController.module"
                    operations="editOfferingController.operations"
                    on-cancel="editOfferingController.close()"
                    hide-edit="editOfferingController.hideEdit"
                    workflow-mode="editOfferingController.workflowMode">
                </edit-module-offering>
            `
        });
    };

    return instance;
});

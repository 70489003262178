'use strict';

/**
 * @ngdoc components
 * @name uasApp.component:charLeft
 * @description Shows how many chars are left.
 * If content is bound to ng-model, ng-model-options="{ allowInvalid: true }" is required to prevent the model from becoming empty when exceeding the limit.
 */
angular.module('uasApp').component('charLeft', {
  templateUrl: 'es6/app/forms/char.left.html',
  bindings: {
    limit: '<',
    content: '<',
    hideValidMaxLength: '<?'
  },
  controllerAs: 'charLeftController',
  controller: function (taLength) {
    const charLeftController = this;

    charLeftController.$onChanges = function () {
      const content = _.trim(charLeftController.content);
      const length = taLength.getTextLength(content);

      charLeftController.remaining = charLeftController.limit - length;
      charLeftController.exceeded = charLeftController.remaining < 0;

      charLeftController.show = shouldShow();
    };

    function shouldShow() {
      if (angular.isUndefined(charLeftController.limit) || charLeftController.limit === 0) {
        return false;
      }

      if (charLeftController.hideValidMaxLength && !charLeftController.exceeded) {
        return false;
      }

      return true;
    }
  }
});

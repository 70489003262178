'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:activityFormPage
 * @description
 */
angular.module('uasApp').component('activityFormPage', {
    bindings: {
        entity: '<',
        period: '<',
        operations: '<',
        page: '<'
    },
    templateUrl: 'es6/schedule/activity/form/activity.form.page.html',
    controllerAs: 'activityFormPageController',
    controller: function ($q, Activity, EntityService, Category, MethodType, Module, Offering, Page, Message, WorkflowValidator) {
        const activityFormPageController = this;

        activityFormPageController.$onInit = function () {
            WorkflowValidator.setValidity(() => activityFormPageController.activityForm);

            const modulePath = isModule() ? 'id' : 'moduleId';
            activityFormPageController.moduleId = _.get(activityFormPageController.entity, modulePath);
            activityFormPageController.categoryId = Page.getParameterAsInt(activityFormPageController.page, 'category');

            if (angular.isDefined(activityFormPageController.moduleId)) {
                loadData();
            }
        };

        function isModule() {
            const entityType = EntityService.getType(activityFormPageController.entity);
            return entityType === 'module';
        }

        activityFormPageController.$onDestroy = function () {
            WorkflowValidator.reset();
        };

        function loadData() {
            activityFormPageController.loading = true;
            return $q.all([
                Offering.periods({
                    periodId: _.get(activityFormPageController.period, 'id'),
                    entityType: 'module',
                    entityId: activityFormPageController.moduleId
                }).$promise,
                Module.find(activityFormPageController.moduleId).$promise,
                getCategory()
            ]).then(([periods, module, category]) => {
                activityFormPageController.periods = periods;
                activityFormPageController.module = module;
                activityFormPageController.category = category;

                if (angular.isUndefined(activityFormPageController.periodId)) {
                    const firstPeriod = _.head(periods);
                    activityFormPageController.onPeriod(firstPeriod);
                }
            }).finally(() => {
                activityFormPageController.loading = false;
            });
        }

        function getCategory() {
            if (angular.isDefined(activityFormPageController.categoryId)) {
                return Category.find(activityFormPageController.categoryId).$promise;
            }

            if (!isModule()) {
                return MethodType.find(activityFormPageController.entity.typeId).$promise.then((type) => {
                    const categoryId = _.get(type, 'categoryId');
                    return Category.find(categoryId).$promise;
                });
            }

            return $q.resolve();
        }

        function loadPeriod(period) {
            period.loading = true;
            $q.all([
                Offering.weeks({
                    entityType: 'module',
                    entityId: activityFormPageController.moduleId,
                    periodId: period.id
                }).$promise,
                getActivities(period)
            ]).then(([weeks, activities]) => {
                period.weeks = weeks;

                period.activities = _(activities).map((activity) => {
                    activity.weeks = Activity.getWeeks(period.weeks, activity);
                    activity.periodId = _.get(activity, 'period.id');
                    return activity;
                }).sortBy(['code', 'id']).value();

                period.activity = _.head(period.activities);
            }).finally(() => {
                period.loaded = true;
                period.loading = false;
            });
        }

        function getActivities(period) {
            return Activity.query({
                entityType: EntityService.getType(activityFormPageController.entity),
                entityId: activityFormPageController.entity.id,
                periodId: _.get(period, 'id')
            }).$promise;
        }

        activityFormPageController.onPeriod = function (period) {
            activityFormPageController.periodId = _.get(period, 'id');

            if (angular.isDefined(period) && period.loaded !== true) {
                loadPeriod(period);
            }
        };

        activityFormPageController.onSelect = function (activity, period) {
            period.activity = activity;
        };

        activityFormPageController.create = function (period) {
            const activity = {
                typeId: activityFormPageController.entity.typeId,
                entity: EntityService.toReference(activityFormPageController.entity),
                periodId: period.id,
                weeks: Activity.getWeeks(period.weeks)
            };

            period.activity = activity;
        };

        activityFormPageController.save = function (data) {
            const activity = Activity.build(data, data.weeks);

            return Activity.store(activity).$promise.then(() => {
                Message.onSaved();
                onChange();
            });
        };

        activityFormPageController.remove = function (data) {
            const activity = Activity.build(data, data.weeks);
            _.forEach(activity.plannings, (planning) => {
                planning.active = false;
            });

            return Activity.store(activity).$promise.then(() => {
                Message.onRemoved();
                onChange();
            });
        };

        function onChange() {
            const period = _.find(activityFormPageController.periods, { id: activityFormPageController.periodId });
            if (angular.isDefined(period)) {
                loadPeriod(period);
            }
        }

        activityFormPageController.onChange = function () {
            loadData().then(() => {
                onChange();
            });
        };
    }
});
'use strict';

/**
 * @ngdoc service
 * @name uasApp.factory:WorkflowModal
 * @description
 * The WorkflowModal service.
 */
angular.module('uasApp').factory('WorkflowModal', function ($q, $uibModal, AuthService, EntityType, Workflow, feedbackObserver) {

    const EMPTY_TEMPLATE = `
        <div class="modal-header">
            <button type="button" class="close" ng-click="workflowModalStateController.onCancel()"></button>
            <h2 class="modal-title">
                <entity-icon entity-type="workflowModalStateController.entityType"></entity-icon>
                <b>{{ workflowModalStateController.entity.code }}</b> 
                <span>{{ workflowModalStateController.entity | entityTranslate }}</span>
            </h2>
        </div>
        <div class="modal-body">
            <div class="alert alert-warning m-b-0" translate="Static.Modal.NoWorkflow"></div>
        </div>
        <div class="modal-footer">
            <cancel-button class="pull-left" ng-click="workflowModalStateController.onCancel()"></cancel-button>
        </div>
    `;

    function open(args) {
        const entityId = _.result(args, 'entity.id');
        const entityType = _.result(args, 'entity.self.type');

        $q.all([
            AuthService.operations(entityType, entityId),
            getWorkflow(args)
        ]).then(([operations, workflow]) => {
            if (angular.isUndefined(workflow) && _.isFunction(args.onEmpty)) {
                return args.onEmpty();
            }

            const template = getTemplate(workflow, args);

            // Loading feedback will re-render the complete page because of the loader component.
            // The re-render causes problems with the dirty check of forms on workflow pages.
            // Feedback loading is temporary disabled and enabled when leaving the workflow modal.
            feedbackObserver.disable();

            $uibModal.open({
                controllerAs: 'workflowModalStateController',
                keyboard: false,
                size: 'xl',
                template: template,
                controller: function ($uibModalInstance) {
                    _.assign(this, args.scope || {});

                    this.entity = args.entity;
                    this.entityType = args.entityType || entityType;
                    this.extensions = args.extensions;
                    this.period = args.period;
                    this.workflow = workflow;
                    this.operations = operations;
                    this.workflowMode = args.workflowMode;

                    this.onSave = closeModal(args.onSave);

                    if (_.isFunction(args.onDelete)) {
                        this.onDelete = closeModal(args.onDelete);
                    }

                    if (angular.isDefined(workflow)) {
                        this.onCancel = closeModal(args.onCancel || args.onClose);
                        this.onClose = closeModal(args.onClose || args.onCancel);

                        if (workflow.deletable !== true) {
                            delete this.onDelete;
                        }
                    } else {
                        this.onCancel = this.onClose = closeModal();
                    }

                    function closeModal(callback) {
                        return (a, b, c) => {
                            // Enable feedback and call dataChanged that will reload feedback if observer is defined
                            feedbackObserver.enable();
                            feedbackObserver.dataChanged();

                            $uibModalInstance.close(a, b, c);
                            if (_.isFunction(callback)) {
                                callback(a, b, c);
                            }
                        };
                    }
                }
            });
        });
    }

    function getWorkflow(args) {
        if (angular.isDefined(args.workflow)) {
            return $q.resolve(args.workflow);
        } else if (angular.isDefined(args.workflowId)) {
            return Workflow.find(args.workflowId).$promise;
        }

        const entityType = _.result(args, 'entity.self.type');
        return EntityType.get({
            rootType: entityType,
            entityType: entityType
        }).$promise.then((type) => {
            return Workflow.find(type.workflowId).$promise;
        });
    }

    function getTemplate(workflow, args) {
        if (angular.isUndefined(workflow)) {
            return _.result(args, 'template', EMPTY_TEMPLATE);
        }

        return `
            <uas-workflow-modal
                entity="workflowModalStateController.entity"
                workflow="workflowModalStateController.workflow"
                extensions="workflowModalStateController.extensions"
                operations="workflowModalStateController.operations"
                on-save="workflowModalStateController.onSave()"
                on-cancel="workflowModalStateController.onCancel"
                on-delete="workflowModalStateController.onDelete"
                on-close="workflowModalStateController.onClose()">
            </uas-workflow-modal>
        `;
    }

    function openProcess(args) {
        const processId = _.get(args.participant, 'processId');
        const entity = {
            id: args.entity.self.id,
            type: args.entity.self.type
        };
        if (processId) {
            Workflow.begin({
                entity,
                process: processId
            }).$promise.then((workflow) =>
                $q.all([
                    Workflow.participant({ id: workflow.id }).$promise,
                    AuthService.operations(entity.type, entity.id)])
            ).then(([participate, operations]) => {
                $uibModal.open({
                    keyboard: false,
                    size: 'xl',
                    templateUrl: 'es6/workflow/workflow.process.modal.html',
                    controllerAs: 'workflowModalController',
                    controller: function ($uibModalInstance) {
                        this.entity = participate.entity;
                        this.participant = participate.participant;
                        this.workflow = participate.workflow;
                        this.process = participate.process;
                        this.operations = operations;

                        this.onStep = function(pageId) {
                            this.currentStep = pageId;
                        };

                        this.onComplete = closeModal(args.onClose);
                        this.onDelete = closeModal(args.onDelete);
                        this.onError = closeModal(args.onClose);

                        function closeModal(callback) {
                            return () => {
                                $uibModalInstance.close();
                                if (_.isFunction(callback)) {
                                    callback(participate.entity.self);
                                }
                            };
                        }
                    }
                });
            });
        }
    }

    return { open, openProcess };

});
'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:trackReport
 * @description
 * The trackReport component.
 */
angular.module('uasApp').component('trackReport', {
    templateUrl: 'es6/schedule/track/report/track.report.html',
    bindings: {
        entity: '<',
        parameters: '<?',
        operations: '<',
        defaultTemplate: '<?',
        hideReportTemplates: '<?'
    },
    controllerAs: 'trackReportController',
    controller: function (Language, Page, EntityService, Report) {
        const trackReportController = this;

        trackReportController.$onInit = function() {
            trackReportController.onLanguage = Language.onChange(loadData);
        };

        trackReportController.$onChanges = function() {
            trackReportController.exam = _.get(trackReportController.parameters, 'exam', false);
            trackReportController.context = `${trackReportController.exam ? 'assessment' : 'method'}-tracks`;
            
            loadData();
        };

        trackReportController.$onDestroy = function() {
            trackReportController.onLanguage();
        };

        function loadData() {
            trackReportController.loading = true;
            Report.tracks({
                definition: true,
                exam: trackReportController.exam
            }).$promise.then((report) => {
                trackReportController.columns = report.headers;
                trackReportController.groups = report.groups;
            }).finally(() => {
                trackReportController.loading = false;
            });
        }

        trackReportController.getRows = function(pageable) {
            const parameters = getParameters(pageable);
            return Report.tracks(parameters).$promise;
        };

        function getParameters(pageable, total) {
            const parameters = _.extend({
                academicYearId: sessionStorage.academicYear,
                exam: trackReportController.exam,
                total
            }, trackReportController.parameters);

            return _.extend(pageable, parameters);
        }
    }
});

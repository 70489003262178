'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:workflowTabs
 * @description Shows a list of tabs in a workflow
 */
angular.module('uasApp')
  .component('workflowTabs', {
    bindings: {
      tabs: '<',
      onTab: '&'
    },
    templateUrl: 'es6/app/tabs/workflow.tabs.html',
    controllerAs: 'workflowTabsController',
    controller: function () {
      const workflowTabsController = this;

      workflowTabsController.filter = function(tab) {
        return angular.isUndefined(tab.visible) || tab.visible === true;
      };
    }
  });

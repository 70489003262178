'use strict';

/**
 * @ngdoc component
 * @name uasApp.component:statusTransitionLabel
 * @description Shows a status transition
 */
angular.module('uasApp').component('statusTransitionLabel', {
    bindings: {
      sourceStatus: '<',
      targetStatus: '<',
      hideEmpty: '<?'
    },
    templateUrl: 'es6/status/transition/status.transition.label.html',
    controllerAs: 'statusTransitionLabelController',
    controller: function() {
      const statusTransitionLabelController = this;

      statusTransitionLabelController.$onChanges = function() {
        statusTransitionLabelController.show = shouldShow();

        if (statusTransitionLabelController.show) {
          statusTransitionLabelController.sourceIndex = _.get(statusTransitionLabelController.sourceStatus, 'index', 0);
          statusTransitionLabelController.targetIndex = _.get(statusTransitionLabelController.targetStatus, 'index', 0);
          statusTransitionLabelController.success = statusTransitionLabelController.targetIndex >= statusTransitionLabelController.sourceIndex;
        }
      };

      function shouldShow() {
        if (statusTransitionLabelController.hideEmpty) {
          if (angular.isUndefined(statusTransitionLabelController.sourceStatus) && angular.isUndefined(statusTransitionLabelController.targetStatus)) {
            return false;
          }
        }

        return true;
      }
    }
  });

'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:checkboxSelect
 * @description
 * Select a single value based on a checkbox
 */
angular.module('uasApp').component('checkboxSelect', {
    bindings: {
      propertyKey: '@',
      items: '<'
    },
    require: {
      ngModelCtrl: 'ngModel'
    },
    templateUrl: 'es6/app/forms/checkbox.select.html',
    controllerAs: 'checkboxSelectController',
    controller: function() {
        const checkboxSelectController = this;

        checkboxSelectController.$onInit = function() {
          checkboxSelectController.ngModelCtrl.$render = function() {
            checkboxSelectController.value = checkboxSelectController.ngModelCtrl.$modelValue;
            setSelected();
          };
        };

        checkboxSelectController.$onChanges = function() {
          checkboxSelectController.options = _.map(checkboxSelectController.items, (item) => {
            return {
              value: _.get(item, checkboxSelectController.propertyKey),
              label: item.name
            };
          });

          setSelected();
        };

        checkboxSelectController.onSelect = function (option) {
          checkboxSelectController.value = option.value;
          checkboxSelectController.ngModelCtrl.$setViewValue(checkboxSelectController.value);
          setSelected();
        };

        function setSelected() {
          _.forEach(checkboxSelectController.options, (option) => {
            option.selected = option.value === checkboxSelectController.value;
          });
        }
    }
});

'use strict';

angular.module('uasApp').component('activityGrid', {
  templateUrl: 'es6/schedule/activity/grid/activity.grid.html',
  bindings: {
    children: '<',
    module: '<',
    period: '<',
    categoryId: '<?',
    showGrouped: '<?',
    hideScheduleWeek: '<?',
    hideDate: '<?'
  },
  controllerAs: 'activityGridController',
  controller: function ($q, Activity, Offering, ModuleWeek) {
    const activityGridController = this;

    activityGridController.$onInit = function () {
      activityGridController.layouts = [
        {
          name: 'regular',
          label: 'Static.Page.Schedule.Report.OpenOriginalDisplay',
          decorator: regular
        }
      ];

      if (activityGridController.showGrouped) {
        activityGridController.layouts.push({
          name: 'grouped',
          label: 'Static.Page.Schedule.Report.OpenGroupedDisplay',
          decorator: grouped
        });
      }

      activityGridController.layout = 'regular';
      loadData();
    };

    activityGridController.onLayout = function (layout) {
      activityGridController.layout = layout.name;
      setRows();
    };

    function loadData() {
      activityGridController.loading = true;

      if (!angular.isDefined(activityGridController.children)) {
        activityGridController.children = [];
      }

      const childrenIds = _.map(activityGridController.children, 'id');
      const params = _.extend(activityGridController.searchParams, {
          entityType: 'module',
          entityId: _.concat(activityGridController.module.id, childrenIds),
          periodId: activityGridController.period.id,
          categoryId: activityGridController.categoryId
      });

      $q.all([
        Activity.query(params).$promise.then((activities) => {
          return _.sortBy(activities, ['type.category.sequence', 'type.sequence', 'name']);
        }),
        getWeeks()
      ]).then(([activities, weeks]) => {
        const plannings = _(activities).map(detach).flatten().value();

        const decorated = _.map(weeks, (week) => {
          return _.extend(week, {
            plannings: _(plannings).filter({ week: week.week }).sortBy('sequence').value()
          });
        });

        activityGridController.weeks = reduce(decorated);
        activityGridController.activities = activities;

        setRows();
      }).finally(() => {
        activityGridController.loading = false;
      });
    }

    function getWeeks() {
      return $q.all([
        Offering.weeks({
          entityType: 'module',
          entityId: activityGridController.module.id,
          periodId: activityGridController.period.id,
          exceedPeriod: true
        }).$promise,
        ModuleWeek.query({
          moduleId: activityGridController.module.id,
          periodId: activityGridController.period.id
        }).$promise
      ]).then(([weeks, settings]) => {
        return _.map(weeks, (week) => {
          const setting = _.find(settings, { week: week.week });
          return _.extend(week, setting);
        });
      });
    }

    function setRows() {
      const layout = _.find(activityGridController.layouts, {
        name: activityGridController.layout
      });

      const execute = layout.decorator || regular;
      const values = execute(activityGridController.weeks, activityGridController.activities);

      _.extend(activityGridController, values);
    }

    function detach(activity) {
      const detached = _.omitBy(activity, 'plannings');
      return _.map(activity.plannings, (planning) => {
        return _.extend(planning, {
          activity: detached
        });
      });
    }

    function reduce(weeks) {
      // First partition the weeks into an array of arrays, where each array contains all weeks that are equal.
      const base = {
        lastKey: null,
        total: []
      };

      const reduced = _.reduce(weeks, (accumulator, week) => {
        // The key consists of all activity's type.id's collected in an array, for example: [1, 2, 3]
        // together with keepSequence
        const activities = _(week.plannings)
          .map((planning) => '' + planning.activity.id)
          .value();
        const key = {
          activities,
          keepSequence: week.keepSequence === true
        };

        let lastKey = accumulator.lastKey;
        const total = accumulator.total;

        // If the lastKey is equal to this key, then it is the same as the previous week, so push them together.
        if (lastKey !== null && _.isEqual(key, lastKey)) {
          _.last(total).push(week);
        } else { // If they do not match this is the start of a new array.
          total.push([week]);
        }

        lastKey = key;

        return { lastKey, total };
      }, base);

      return _(reduced.total)
        // Turn each partition of multiple weeks into a single week object
        .map((partition) => {
          const start = _.head(partition);
          const end = _.last(partition);

          const displayWeeks = _.map(_.range(start.week, end.week + 1), (week, index) => ({
            week: week,
            yearWeek: start.yearWeek + index
          }));

          return {
            periods: _.range(start.period, end.period + 1),
            weeks: displayWeeks,
            scheduleWeeks: _.range(start.scheduleWeek, end.scheduleWeek + 1),
            calendarWeeks: _.range(start.yearWeek, end.yearWeek + 1),
            startDate: start.startDate,
            endDate: end.endDate,
            keepSequence: _.some(partition, { keepSequence: true }),
            plannings: start.plannings
          };
        })
        .sortBy('week')
        .value();
    }

    // Layouts

    function regular(weeks) {
      const headers = [{}];

      const rows = _.map(weeks, (week) => {
        return _.extend(week, {
          headers: [{
            plannings: week.plannings
          }]
        });
      });

      return {
        headers: headers,
        rows: rows
      };
    }

    function grouped(weeks, activities) {
      const headers = angular.copy(activities);

      const rows = _.map(weeks, (week) => {
        const row = angular.copy(week);
        row.headers = _.map(activities, (activity) => {
          return {
            plannings: _.filter(week.plannings, (planning) => planning.activity.id === activity.id)
          };
        });
        return row;
      });

      return {
        headers: headers,
        rows: rows
      };
    }
  }
});

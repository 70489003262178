'use strict';

/**
 * @ngdoc function
 * @name uasApp.controller:ModuleDetailsModalCtrl
 * @description
 * # ModuleDetailsModalCtrl
 * The controller for viewing module details in planboard.
 */
angular.module('uasApp')
  .controller('ModuleDetailsModalCtrl', function(
    $scope, $uibModalInstance, $state, $window, entityTranslateFilter, translateFilter,
    ModuleGroupModule, Message, Feedback, Language, Faculty, Study, Structures,
    board, mode, periods, offering, module, group, removeMode, onLeave) {

        $scope.mode = mode;

        if (angular.isDefined(module.moduleFacultyId)) {
            $scope.moduleFaculty = Faculty.get({
                id: module.moduleFacultyId
            });
        }
        if (angular.isDefined(module.moduleStudyId)) {
            $scope.moduleStudy = Study.get({
                id: module.moduleStudyId
            });
        }

        $scope.required = module.required === true;

        $scope.group = group;
        $scope.module = module;
        $scope.offering = offering;
        $scope.removeMode = angular.isUndefined(removeMode) ? false : removeMode;

        $scope.selection = !$scope.removeMode;
        $scope.feedback = {};

        if (angular.isDefined(module.id)) {
            ModuleGroupModule.query({
                moduleId: module.moduleId
            }).$promise.then(function(mgms) {
                $scope.groups = board.refreshModuleGroupModules(module.moduleId, mgms);
            });

            Feedback.get({
                entityType: 'module-group-module',
                entityId: module.id,
                language: Language.get(),
                root: false
            }).$promise.then(function(feedback) {
                $scope.feedback.module = feedback;
            });
        }

        if (offering) {
            $scope.period = _.find(periods, {
                id: offering.period.id
            });
            $scope.isRemoved = module.changeType === 'REMOVE' || offering.changeType === 'REMOVE';

            if (angular.isDefined(offering.id)) {
                Feedback.get({
                    entityType: 'offering',
                    entityId: offering.moduleOfferingId || offering.id,
                    language: Language.get(),
                    root: false
                }).$promise.then(function(feedback) {
                    $scope.feedback.offering = feedback;
                });
            }
        }

        $scope.goToModule = function() {
            const redirectUrl = $state.href('module.general', {
                id: $scope.module.moduleId
            });

            $window.open(redirectUrl, '_blank');
        };

        $scope.edit = function() {
            onLeave(edit, () => {
                $uibModalInstance.close();
            });
        };

        function edit() {
            Structures.editModule({
                id: $scope.module.moduleId,
                onSuccess: () => board.refreshBoard(),
                onCancel: () => board.refreshBoard()
            });
        }

        $scope.toggleRequired = function() {
            board.toggleRequired(module);
        };

        $scope.toggleSelection = function() {
            $scope.selection = !$scope.selection;
        };

        $scope.getModuleNameObject = function() {
            return '{ module: "' + entityTranslateFilter($scope.module, 'moduleLocalName', 'moduleEnglishName') + '" }';
        };

        function getModuleName(entity) {
            return entityTranslateFilter(entity, 'moduleLocalName', 'moduleEnglishName');
        }

        function getModuleGroupName(entity) {
            return entityTranslateFilter(entity, 'moduleGroupLocalName', 'moduleGroupEnglishName');
        }

        $scope.removeModuleFromGroup = function() {
            board.removeModuleFromGroup(module, group);
            $uibModalInstance.close(Message.addSuccess(translateFilter('Static.Modal.Module.RemoveGroup', {
                moduleName: getModuleName(module),
                groupName: getModuleGroupName(group)
            })));
        };

        $scope.removeModuleOffering = function() {
            if (module.owned) {
                const groupName = getModuleGroupName(group);
                const periodName = entityTranslateFilter(offering.period);

                board.removeOffering(offering);
                $uibModalInstance.close(
                    Message.addSuccess(translateFilter('Static.Modal.Module.RemoveOffering', {
                        period: periodName,
                        name: groupName
                    }))
                );
            } else {
                $uibModalInstance.close(Message.addError(translateFilter('Static.Modal.Module.Remove.Error')));
            }
        };

        $scope.removeModule = function() {
            if ($scope.module.owned) {
                $scope.module.moduleTerminated = true;
                $scope.module.terminatePerformed = true;
                board.removeModule($scope.module);
                $scope.module.dirty = true;
                $uibModalInstance.close();
            } else {
                $uibModalInstance.close(Message.addError(translateFilter('Static.Modal.Module.Remove.Error')));
            }
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        };

        $scope.describePeriods = function(period) {
          if (angular.isDefined(period)) {
            return entityTranslateFilter(period);
          } else {
            return '';
          }
        };

    });

'use strict';

/**
 * The state config for the main module 'uasApp'.
 */
angular.module('uasApp').config(function($stateProvider) {

    $stateProvider.state('start-workflow', {
        url: '/{entityType:.+}/{entityId:[0-9]+}/start-workflow/{id:[0-9]+}',
        resolve: {
            execution: function(Participant, $stateParams) {
                return Participant.get({
                    id: $stateParams.id
                }).$promise;
            }
        },
        controllerAs: 'startWorkflowController',
        controller: function ($stateParams, execution) {
            const startWorkflowController = this;
            startWorkflowController.entity = {
                type: $stateParams.entityType,
                id: $stateParams.entityId
            };
            startWorkflowController.processId = execution.processId;
        },
        breadcrumb: {
            label: 'Static.Page.Workflow',
            root: false
        },
        template: `
            <uas-workflow-begin
                entity="startWorkflowController.entity"
                process-id="startWorkflowController.processId">
            </uas-workflow-begin>
        `
    });

    $stateProvider.state('start-process', {
        url: '/{entityType:.+}/{entityId:[0-9]+}/start-process/{processId:[0-9]+}',
        controllerAs: 'startProcessController',
        controller: function ($stateParams) {
            const startProcessController = this;
            startProcessController.entity = {
                type: $stateParams.entityType,
                id: $stateParams.entityId
            };
            startProcessController.processId = $stateParams.processId;
        },
        breadcrumb: {
            label: 'Static.Page.Workflow',
            root: false
        },
        template: `
            <uas-workflow-begin
                entity="startProcessController.entity"
                process-id="startProcessController.processId">
            </uas-workflow-begin>
        `
    });

    $stateProvider.state('workflows', {
        url: '/workflows',
        template: '<uas-workflow-list></uas-workflow-list>',
        breadcrumb: {
            label: 'Static.Page.Workflow',
            root: true
        },
        admin: true
    });

    $stateProvider.state('workflow', {
        url: '/{entityType:.+}/{entityId:[0-9]+}/workflow/{id:[0-9]+}/page/{pageId:[0-9]+}?processId',
        resolve: {
            data: function (Workflow, $stateParams) {
                return Workflow.participant({
                    id: $stateParams.id
                }).$promise;
            },
            operations: function(AuthService, $stateParams) {
                return AuthService.operations($stateParams.entityType, $stateParams.entityId);
            }
        },
        controllerAs: 'workflowStateController',
        controller: function ($state, $stateParams, $rootScope, data, operations) {
            const workflowStateController = this;

            workflowStateController.$onInit = function() {
                workflowStateController.entity = data.entity;
                workflowStateController.participant = data.participant;
                workflowStateController.participants = data.participants;
                workflowStateController.assignee = data.assignee;
                workflowStateController.workflow = data.workflow;
                workflowStateController.process = data.process;
                workflowStateController.operations = operations;
                workflowStateController.currentStep = parseInt($stateParams.pageId);
            };

            workflowStateController.onStep = function (pageId) {
                let params = angular.copy($stateParams);
                const extended = _.extend(params, { pageId });
                return $state.go('workflow', extended, { reload: true });
            };

            workflowStateController.onComplete = function() {
                $state.go('dashboard');
            };

            workflowStateController.onDelete = function() {
                $state.go('dashboard');
            };

            workflowStateController.onError = function() {
                $state.go('dashboard');
            };

            workflowStateController.onBeforeComplete = function(callback) {
                $rootScope.$broadcast('beforeCompleteWorkflow', callback);
            };
        },
        template: `
            <uas-workflow-wizard
                entity="workflowStateController.entity"
                participant="workflowStateController.participant"
                assignee="workflowStateController.assignee"
                participants="workflowStateController.participants"
                workflow="workflowStateController.workflow"
                process="workflowStateController.process"
                current-step="workflowStateController.currentStep"
                operations="workflowStateController.operations"
                on-step="workflowStateController.onStep(pageId)"
                on-before-complete="workflowStateController.onBeforeComplete(callback)"
                on-complete="workflowStateController.onComplete()"
                on-delete="workflowStateController.onDelete()"
                on-error="workflowStateController.onError()">
            </uas-workflow-wizard>
        `,
        breadcrumb: {
            root: true
        }
    });

});

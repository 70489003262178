'use strict';

angular.module('uasApp').factory('Promises', function($q) {

    function chain(promises) {
        let progress = {
            loading: true,
            current: 0,
            total: promises.length
        };

        progress.$promise = _.reduce(promises, (promise, proceed) => {
            return promise.then((current) => {
                return proceed().then((next) => {
                    return _.concat(current, next);
                }).finally(() => {
                    progress.current += 1;
                });
            });
        }, resolve([])).finally(() => {
            progress.loading = false;
        });

        return progress;
    }

    function pages(initial, getPage) {
        let progress = {
            loading: true,
            current: 0,
            total: 1
        };

        let content = [];

        progress.$promise = fetch(initial).then(() => {
            return content;
        });

        function fetch(pageable) {
            return getPage(pageable.build()).then((page) => {
                content = content.concat(page.content || []);

                const hasNext = page.last !== true;
                progress.loading = hasNext;
                progress.current = page.number * page.size;
                progress.total = page.numberOfElements;
    
                if (hasNext) {
                    return fetch(pageable.next());
                }
            });
        }

        return progress;
    }

    function resolve(value) {
        return $q.resolve(value);
    }

    function wait(value) {
        if (isPromise(value)) {
            return value;
        }

        return resolve(value);
    }

    function isPromise(value) {
        const then = _.result(value, 'then');
        return _.isFunction(then);
    }

    return {
        chain, pages, resolve, wait
    };

});

'use strict';

/**
 * Adds a lock with a tooltip showing the permissions that the user requires.
 * @param {Array} requiredPermissions These are the permissions that the user needs.
 * @param {Array} operations These are the permissions that the user has.
 * @param {String} entityType The type of entity to which the required permissions and operations are binded.
 * @param {Number} index Necessary for the correct placement of the tooltip.
 */
angular.module('uasApp').component('permissionLock', {
    bindings: {
        'requiredOperations': '<',
        'operations': '<',
        'entityType': '@',
        'index': '<'
    },
    controllerAs: 'permissionLockController',
    template: `
        <i class="fa fa-lock"
           uib-tooltip-html="permissionLockController.tooltip"
           tooltip-placement="{{ permissionLockController.tooltipPlacement }}"
           tooltip-append-to-body="true">
        </i>
    `,
    controller: function ($translate, typeFilter, enumFilter) {
        const permissionLockController = this;

        // Necessary to that the tooltip does not get clipped in nowherelands on the user screen
        permissionLockController.tooltipPlacement = permissionLockController.index < 2 ? 'right' : 'top-right';

        const requiredOperationNames = _.map(permissionLockController.requiredOperations, enumFilter);
        const availableOperationNames = _.map(permissionLockController.operations, enumFilter);

        const missingTxt = _(requiredOperationNames)
            .difference(availableOperationNames)
            .sortBy()
            .value()
            .join(', ');

        // Placeholder before it is translated
        permissionLockController.tooltip = 'You are missing one or more permissions';

        $translate('Static.Security.Feedback.MissingOneOf', {
            entityType: typeFilter(permissionLockController.entityType),
            permissions: missingTxt
        }).then((txt) => {
            permissionLockController.tooltip = txt;
        });
    }
});

'use strict';

angular.module('uasApp').component('customFieldInputGroup', {
  bindings: {
    customField: '<',
    inputId: '@?',
    labelKey: '@?',
    labelWidth: '@',
    operations: '<',
    operationsToEdit: '<?',
    isNew: '<?',
    isPublished: '<?',
    isDisabled: '<?',
    isReadOnly: '<?',
    isRequired: '<?',
    hideNotAllowed: '<?',
    textPattern: '<?',
    arrayModel: '<?',
    evaluation: '<?',
    maxLength: '<?',
    hideValidMaxLength: '<?',
    minValue: '<?',
    maxValue: '<?',
    extensions: '<?',
    filterValues: '<?',
    excludeIds: '<?' // Ids that should be excluded from options
  },
  require: {
    ngModelCtrl: 'ngModel'
  },
  templateUrl: 'es6/fields/custom.field.input.group.html',
  controllerAs: 'customFieldController',
  controller: function (AuthService, SecurityService, Expression, Changes, CustomField, Language, Util) {
    const customFieldController = this;
    const API_GENERATED = /.*generate( )*\(.*\).*/;

    customFieldController.$onInit = function () {
      customFieldController.labelWidth_ = _.isEmpty(customFieldController.labelWidth) ? '3' : customFieldController.labelWidth;
      customFieldController.onLanguage = Language.onChange(setLabel);

      customFieldController.ngModelCtrl.$render = function() {
        customFieldController.model = customFieldController.ngModelCtrl.$modelValue;
        if (angular.isDefined(customFieldController.customFieldExtended)) {
          customFieldController.model = CustomField.parseValue(customFieldController.model, customFieldController.customFieldExtended.valueType);
          evaluateAll();
        }
      };
    };

    function setLabel() {
      customFieldController.label = CustomField.getLabel(customFieldController.customFieldExtended, customFieldController.labelKey);
    }

    customFieldController.$onDestroy = function () {
      customFieldController.onLanguage();
    };

    customFieldController.$onChanges = function (changes) {
      if (Changes.hasChanged(changes, ['customField', 'extensions'])) {
        if (angular.isDefined(customFieldController.customField)) {
          customFieldController.customFieldExtended = _.extend(angular.copy(customFieldController.customField), customFieldController.extensions);

          setLabel();
          setOperations();
          setVisible();
        }
      }

      if (Changes.hasChanged(changes, ['evaluation'])) {
        if (angular.isDefined(customFieldController.customFieldExtended) && customFieldController.evaluated) {
          evaluateAll();
        }
      }

      if (Changes.hasChanged(changes, ['isReadOnly', 'isDisabled'])) {
        if (customFieldController.isReadOnly || customFieldController.isDisabled) {
          customFieldController.ngModelCtrl.$setDirty = _.noop;
        }
      }
    };

    function evaluateAll() {
      customFieldController.evaluated = true;

      if (shouldEvaluate(customFieldController.conditionProperties)) {
        evaluate(customFieldController.customFieldExtended.condition, (result) => {
          customFieldController.conditionProperties = getEvaluationProperties(result.properties);

          // Temporary fix for incorrect evaluations causing fields not to save properly.
          // The changes are simply propagated by directly modifying the custom field reference.
          customFieldController.customField.evaluated = result.value === true;
        });
      }

      if (shouldGenerate()) {
        evaluate(customFieldController.customFieldExtended.formula, (result) => {
          customFieldController.formulaProperties = getEvaluationProperties(result.properties);
          setValue(result.value);
        });
      }
    }

    function shouldEvaluate(properties) {
      if (!properties) {
        return true;
      }

      const newProperties = getEvaluationProperties(properties);
      return !_.isEqual(properties, newProperties);
    }

    function shouldGenerate() {
      const formula = _.get(customFieldController.customFieldExtended, 'formula', '');
      if (_.isEmpty(formula)) {
        return false; // No generation needed
      }
      if (API_GENERATED.test(formula) || customFieldController.customFieldExtended.uniqueValue) {
        return false; // Generated by API
      }
      if (customFieldController.customFieldExtended.generate === 'ON_EMPTY') {
        return !CustomField.hasValue(customFieldController.model);
      }
      if (customFieldController.customFieldExtended.generate === 'ON_CREATE') {
        return false;
      }

      return shouldEvaluate(customFieldController.formulaProperties);
    }

    function getEvaluationProperties(properties) {
      return _.transform(properties, (result, _value, key) => {
        result[key] = customFieldController.evaluation.values[key];
      }, {});
    }

    function setOperations() {
      const operation = _.get(customFieldController.customFieldExtended, 'operation');
      if (operation) {
        customFieldController.operationsToEdit_ = [operation];
      } else if (customFieldController.isNew) {
        customFieldController.operationsToEdit_ = AuthService.buildOperationsToEdit(customFieldController.operationsToEdit, ['CREATE']);
      } else {
        customFieldController.operationsToEdit_ = AuthService.buildOperationsToEdit(customFieldController.operationsToEdit, ['EDIT', 'EDIT_WORKFLOW']);
      }
    }

    function setVisible() {
      if (customFieldController.hideNotAllowed === true) {
        customFieldController.allowed = SecurityService.isAllowed(customFieldController.operationsToEdit_, customFieldController.operations);
      } else {
        customFieldController.allowed = true;
      }
    }

    function evaluate(expression, callback) {
      if (customFieldController.evaluation && !_.isEmpty(expression)) {
        const evaluation = angular.copy(customFieldController.evaluation);
        evaluation.expression = expression;
        evaluation.academicYearId = sessionStorage.academicYear;

        Expression.evaluate(evaluation).$promise.then((result) => {
          callback(result);
        });
      }
    }

    function setValue(value) {
      if (customFieldController.arrayModel === true) {
        value = Util.toArray(value);
      }

      const parsed = CustomField.parseValue(value, customFieldController.customFieldExtended.valueType);
      if (!_.isEqual(customFieldController.model, parsed)) {
        customFieldController.model = value;
        setViewValue(value);
      }
    }

    customFieldController.hasValue = function () {
      return CustomField.hasValue(customFieldController.model);
    };

    customFieldController.onChange = function () {
      setViewValue(customFieldController.model);
      evaluateAll();
    };

    function setViewValue(value) {
      customFieldController.ngModelCtrl.$setViewValue(value);
    }
  }
});

'use strict';

angular.module('uasApp').factory('Subject', function (UasResource) {
    const resource = UasResource('/subjects');

    function toModel(subject) {
        let model = angular.copy(subject);

        if (angular.isUndefined(model.typeId)) {
            model.typeId = _.get(model, 'type.id');
            delete model.type;
        }
        if (angular.isUndefined(model.valueId)) {
            model.valueId = _.get(model, 'value.id');
            delete model.value;
        }

        return model;
    }

    return _.extend(resource, { toModel });
});

'use strict';

/**
 * @ngdoc function
 * @name uasApp.component:parameterList
 * @description
 * uasAdminScreen Shows admin parameters
 */
angular.module('uasApp')
    .component('parameterList', {
        templateUrl: 'es6/admin/parameters/parameter.list.html',
        controllerAs: 'parameterListController',
        controller: function ($q, Parameter, Message) {
            const parameterListController = this;

            parameterListController.$onInit = function () {
                loadData();
            };

            function loadData() {
                parameterListController.loading = true;
                Parameter.reload().then((parameters) => {
                    parameterListController.parameters = parameters;
                }).finally(() => {
                    parameterListController.loading = false;
                });
            }

            parameterListController.save = function () {
                const promises = _(parameterListController.parameters)
                    .filter((parameter) => _.result(parameterListController.form[parameter.name], '$dirty'))
                    .map((parameter) => Parameter.save(parameter).$promise)
                    .value();

                $q.all(promises).then(() => {
                    Message.onSaved();
                    loadData();
                });
            };
        }
    });
